/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";

import { LoadingIndicator } from "@quintype/components";

function LoadingIndicatorComponent() {
  return (
    <LoadingIndicator>
      <div className="loader-overlay-container">
         <span className="spinning-loader"></span>
      </div>
    </LoadingIndicator>
  );
}

export { LoadingIndicatorComponent };
