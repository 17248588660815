/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { CustomLink } from '../custom-link';
import { MenuLink } from '../menu-link';
import { HeaderLogo } from './header-logo';
import get from 'lodash/get';
import {
  setSubscriptionRedirect,
  getCustomCssClasses,
  getPath
} from '../../utils';

import './header-helper-components.m.css';

export const getMenuData = (menu, menuGroup, domainSlug = '') => {
  const filteredMenu = domainSlug
    ? menu.filter(
        item => item['menu-group-slug'] === `${menuGroup}-${domainSlug}`
      )
    : menu.filter(item => item['menu-group-slug'] === menuGroup);

  if (menuGroup.includes('sidebar-menu')) {
    if (filteredMenu.length === 0) return getMenuData(menu, 'default');
  }
  return filteredMenu;
};

const GetSubscribeButton = props => {
  const { cssClasses = '' } = props;
  const sketchesHost = useSelector(state =>
    get(state, ['qt', 'config', 'sketches-host'], '')
  );
  const pbConfig = useSelector(state =>
    get(state, ['qt', 'config', 'pagebuilder-config'])
  );
  const { enableLocalization = false, localizedElements = {} } = get(
    pbConfig,
    ['general', 'localization'],
    {}
  );
  const { buttonLabels = {} } = enableLocalization ? localizedElements : {};
  const { subscribe: localizedSubscribe } = buttonLabels;
  const subscriptionUrl = getPath(sketchesHost, '/subscription');
  return (
    <div
      className={cssClasses}
      onClick={() => setSubscriptionRedirect(subscriptionUrl, sketchesHost)}
    >
      {localizedSubscribe || 'Subscribe'}
    </div>
  );
};

export const CtaWrapper = props => {
  const { enableAccesstype, socialFollowId = '' } = props;
  const id = props?.isMobileHeader
    ? 'mobile-header-subscribe-button'
    : 'header-subscribe-button';
  return (
    <>
      {enableAccesstype && (
        <div id={id} data-test-id='header-subscribe'>
          <HeaderSubscribeButton />
        </div>
      )}
      <div id={socialFollowId || 'header-social-follow'} />
    </>
  );
};

export function HeaderSubscribeButton () {
  const sketchesHost = useSelector(state =>
    get(state, ['qt', 'config', 'sketches-host'])
  );
  const pbConfig = useSelector(state =>
    get(state, ['qt', 'config', 'pagebuilder-config'])
  );
  const headerTheme = get(pbConfig, ['header', 'headerStyle'], 'header_1');

  const customCssClasses = getCustomCssClasses(headerTheme);
  const headerClass = headerTheme.startsWith('single')
    ? 'single-layer-header'
    : 'header';
  const cssClasses = [headerClass, customCssClasses].join(' ');

  return (
    <GetSubscribeButton cssClasses={cssClasses} sketchesHost={sketchesHost} />
  );
}
CtaWrapper.propTypes = {
  customCssClasses: PropTypes.string,
  isSingleLayer: PropTypes.bool,
  enableAccesstype: PropTypes.bool,
  localizedElementsData: PropTypes.shape({
    buttonLabels: PropTypes.shape({
      subscribe: PropTypes.string
    })
  })
};

export const LogoWrapper = props => {
  // Setting sketchesHost as the logo handler for now for LogoWrapper and Monogram and Footer.
  // Once subdomain changes are also in place, we may need to check this
  // how this going to playout.
  return (
    <div className='logo-wrapper'>
      <CustomLink
        href={props.logoUrl}
        callback={() => {
          props.closeAll();
          typeof props.windowScroll === 'function' && props.windowScroll();
        }}
      >
        <div id={props?.type || 'header-logo'}>
          <HeaderLogo />
        </div>
      </CustomLink>
    </div>
  );
};

LogoWrapper.propTypes = {
  windowScroll: PropTypes.func,
  closeAll: PropTypes.func,
  headerSecondaryLogo: PropTypes.string,
  logoUrl: PropTypes.string
};

export const getMenuItem = (menuItem, isSidebarMenu = false) => {
  const dispatch = useDispatch();
  const closeMenu = () => dispatch({ type: 'CLOSE_ALL' });
  const menuType =
    isSidebarMenu ||
    menuItem['item-type'] === 'placeholder' ||
    !menuItem.url ||
    !menuItem.completeUrl;
  return menuType ? (
    <span className='title' data-testid='menu-title'>
      {menuItem.title}
    </span>
  ) : (
    <MenuLink item={menuItem} callback={closeMenu} />
  );
};
