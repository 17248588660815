/*
 *  ************************************************************************
 *  *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import { PAGES_WITH_AUTH_HEADER } from "../../constants";

export const authHeaderEnabled = (currentPageType, pbConfig) => {
  const isAheadNxtEnabled = pbConfig?.general?.aheadNxtEnabled || false;
  return isAheadNxtEnabled && PAGES_WITH_AUTH_HEADER.includes(currentPageType);
};
