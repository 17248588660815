/*
 *  ************************************************************************
 *  *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import format from "date-fns/format";
import get from "lodash/get";
import lowerCase from "lodash/lowerCase";
import { QUILL_EMPTY_STRING } from "../../../constants";

export const getDateObj = (timeStamp, localizedMonths) => {
  const months = {
    jan: "January",
    feb: "February",
    mar: "March",
    apr: "April",
    may: "May",
    jun: "June",
    jul: "July",
    aug: "August",
    sep: "September",
    oct: "October",
    nov: "November",
    dec: "December",
  };
  const dd = format(new Date(timeStamp), "dd");
  const month = format(new Date(timeStamp), "MMM");
  const mm = get(localizedMonths, [lowerCase(month)]) || months[lowerCase(month)];
  const yy = format(new Date(timeStamp), "yyyy");
  return { dd, mm, yy };
};

export const textWithFallback = (textStr, fallbackStr) => {
  return !textStr || textStr === QUILL_EMPTY_STRING ? fallbackStr : textStr;
};
