/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
/* eslint-disable global-require */
require("../../app/assets/stylesheets/application.scss");

const get = require("lodash/get");

function preProcessStartApp() {
  const { renderApplication, preRenderApplication } = require("./render");
  const { startApp } = require("@quintype/framework/client/start");
  const { CUSTOM_REDUCERS } = require("./custom-reducers");
  const opts = {
    appVersion: require("../isomorphic/app-version"),
    enableServiceWorker: process.env.NODE_ENV === "production",
    preRenderApplication,
    firebaseConfig: (page) => {
      const {
        notificationType = "",
        enableNotifications = false,
        fcm = {},
      } = get(page, ["config", "pagebuilder-config", "general", "notifications"], {});
      if (enableNotifications && notificationType === "fcm") {
        return fcm;
      }
    },
  };

  function enableHotReload(store) {
    if (process.env.NODE_ENV === "development" && module.hot) {
      module.hot.accept("./render", () => renderApplication(store));
    }
  }
  if (window.OneSignal) {
    Object.assign(opts, {
      serviceWorkerLocation: "/OneSignalSDKWorker.js",
    });
  }
  global.setSvg && global.setSvg();

  startApp(renderApplication, CUSTOM_REDUCERS, opts).then(enableHotReload);
}

if (
  global.fetch &&
  global.Promise &&
  global.URLSearchParams &&
  global.IntersectionObserver &&
  global.Set &&
  Array.prototype.flatMap &&
  Array.from
) {
  preProcessStartApp();
} else {
  global.polyfillCallback = preProcessStartApp;
  const s = document.createElement("script");
  s.src =
    "https://polyfill.io/v3/polyfill.min.js?flags=gated&callback=polyfillCallback&features=IntersectionObserver%2CIntersectionObserverEntry%2Cfetch%2CArray.from%2CArray.isArray%2CArray.of%2CArray.prototype.%40%40iterator%2CArray.prototype.copyWithin%2CArray.prototype.entries%2CArray.prototype.every%2CArray.prototype.fill%2CArray.prototype.filter%2CArray.prototype.reduceRight%2CArray.prototype.reduce%2CArray.prototype.some%2CArray.prototype.map%2CArray.prototype.keys%2CArray.prototype.flatMap%2CArray.prototype.includes%2CArray.prototype.values%2CCustomEvent%2CDOMTokenList%2CDate.now%2CDate.prototype.toISOString%2CDocumentFragment%2CDocumentFragment.prototype.append%2CDocumentFragment.prototype.prepend%2CElement.prototype.after%2CElement.prototype.append%2CElement.prototype.replaceWith%2CElement.prototype.remove%2CElement.prototype.prepend%2CElement.prototype.placeholder%2CElement.prototype.matches%2CElement.prototype.dataset%2CElement.prototype.closest%2CElement.prototype.cloneNode%2CElement.prototype.classList%2CElement.prototype.before%2CElement%2CEvent.focusin%2CEvent.hashchange%2CEvent%2CFunction.prototype.bind%2CJSON%2CMap%2CNode.prototype.contains%2CNumber.isNaN%2CObject.assign%2CObject.create%2CObject.defineProperties%2CObject.defineProperty%2CObject.getOwnPropertyDescriptor%2CObject.getOwnPropertyNames%2CObject.keys%2CObject.getPrototypeOf%2CPromise%2CSet%2CString.prototype.endsWith%2CString.prototype.includes%2CString.prototype.startsWith%2CString.prototype.trim%2CWindow%2CXMLHttpRequest%2Catob%2Cdocument.querySelector%2Cdocument.visibilityState%2Cdocument%2Clocation.origin%2CrequestAnimationFrame%2C%7Ehtml5-elements%2CArray.prototype.find%2CArray.prototype.findIndex%2CArray.prototype.flat%2CArray.prototype.forEach%2CArray.prototype.indexOf%2CArray.prototype.lastIndexOf";
  document.getElementsByTagName("head")[0].appendChild(s);
}
