/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import { SocialShare } from '@quintype/components'
import { renderComponent } from '@quintype/framework/client/start'
import camelCase from 'lodash/camelCase'
import { PublishDetails } from '@quintype/arrow'
import get from 'lodash/get'
import { findStoryTemplate } from '../isomorphic/component-bundles/bundling-utils'
import { AdSlotRow } from '../isomorphic/components/collection-templates/ad-slot-row'
import { CustomWidgetRow } from '../isomorphic/components/collection-templates/custom-widget-row'
import { AdComponent, WidgetComp } from '../isomorphic/components/DetectComponent'
import { StoryFooter } from '../isomorphic/components/molecules/story-footer'
import Story from '../isomorphic/components/story-templates/story-wrapper/story'
import WithLazyTrigger from '../isomorphic/components/with-lazy-trigger'
import { getUpdatedStoryConfig } from '../utils/story-utils'

// from arrow/src/utils/utils.js
export const facebookMobileVideoResizeFix = () => {
  if (typeof window !== 'undefined' && window.matchMedia && window.matchMedia('(max-width: 767px)').matches) {
    const applyFixAfterTime = 3000
    const aspectRatioAdjustmentFactor = 0.7
    setTimeout(() => {
      document.querySelectorAll('.story-element-jsembed.story-element-jsembed-facebook-post span').forEach(element => {
        if (!element.classList.contains('fixed-mobile-fb-video')) {
          element.style.height = `${Math.ceil(element.offsetHeight * aspectRatioAdjustmentFactor)}px`
          element.classList.add('fixed-mobile-fb-video')
        }
      })
    }, applyFixAfterTime)
  }
}

const hydrateSlotComponent = (SlotComponent, id, store, props) => {
  if (document.getElementById(id)) {
    renderComponent(SlotComponent, id, store, props)
  }
}

export const hydrateStory = (store, storeQTData) => {
  const storeConfig = get(storeQTData, ['config'], {})
  const pbConfig = storeConfig['pagebuilder-config']
  const story = get(storeQTData, ['data', 'story'], {})
  const storyType = camelCase(get(story, ['story-template'], 'text'))
  const enableDarkMode = get(storeQTData, ['header', 'isDarkModeEnabled'], false)
  const storyStoreField = 'story'
  const storyPageType = `${camelCase(story['story-template'])}-story`
  const { timeZone = null, others = {}, enableTimeZoneAbbr = false } = get(pbConfig, ['general'], {})
  const updatedStoryConfig = getUpdatedStoryConfig({
    config: storeConfig,
    story,
    storyPageType,
    storyStoreField,
    templateConfig: {}
  })

  const { rows: pbStoryRows = [], asideSlots: pbAsideSlots = [], settings: storySettings = {} } = get(
    pbConfig,
    ['story', `${storyType}-story`],
    {}
  )
  const storyPageSetting = get(pbConfig, ['story', 'pageSetting'], {})
  const { cards: storyCards = [], id: storyId = '', customSlotAfterStory = {} } = story || {}
  const heroVideo = {}
  let shouldRunFBMobileVideoFix = true

  // Hydrate Story Rows
  pbStoryRows.forEach((row = {}) => {
    const { type, rowId = '' } = row
    const isAd = type === 'ads'
    const isWidget = type === 'widget'
    const slotProps = { metadata: row, storyId: storyId, hydrate: true }

    if (isAd) {
      hydrateSlotComponent(AdSlotRow, `ad-${rowId}_${storyId}`, store, slotProps)
    }

    if (isWidget) {
      hydrateSlotComponent(CustomWidgetRow, `widget-${rowId}_${storyId}`, store, slotProps)
    }
  })

  if (!enableTimeZoneAbbr && document.getElementById(`publish-details-container-${storyId}`)) {
    renderComponent(PublishDetails, `publish-details-container-${storyId}`, store, {
      story,
      template: 'story',
      timezone: timeZone,
      opts: updatedStoryConfig.publishedDetails,
      hydrate: true
    })
  }

  const asideSlotsSupportedStoryTemplates = ['default', 'headline-priority', 'hero-priority-left']

  const template = get(pbConfig, ['story', `${storyType}-story`, 'template'])
  const isHorizontal = !asideSlotsSupportedStoryTemplates.includes(template)

  // Hydrate aside slots

  pbAsideSlots.forEach((slot = {}, index) => {
    const { type } = slot
    const isAd = type === 'ad'
    const isWidget = type === 'widget'
    const props = { ...slot, hydrate: true }

    if (isAd) {
      hydrateSlotComponent(AdComponent, `ad-${index}_${storyId}`, store, props)
    }

    if (isWidget) {
      const targetId = slot.targetId ? `widget-${slot.targetId}-${storyId}` : `widget-${index}_${storyId}`

      hydrateSlotComponent(WidgetComp, targetId, store, props)
    }
  })

  const socialShareContainer = document.getElementById(`story-share-${storyId}`)

  let socialShareRendered = false
// passing light mode theme as the hydration works only on light mode
  if (socialShareContainer) {
    socialShareContainer.addEventListener(
      'click',
      () => {
        if (!socialShareRendered) {
          import('../isomorphic/components/social-share-template').then(SocialShareTemplateModule => {
            const SocialShareTemplate = SocialShareTemplateModule.default
            const socialShareProps = {
              template: SocialShareTemplate,
              fullUrl: story.url,
              title: story.headline,
              theme: storySettings.theme,
              fixedShareLayout: get(storyPageSetting, ['fixedShareLayout'], 'partialShareIcons'),
              socialShareStyle: get(storyPageSetting, ['socialShareStyle']),
              open: true
            }

            const cb = () => {
              socialShareRendered = true
            }

            renderComponent(
              SocialShare,
              `story-share-${storyId}`,
              store,
              {
                ...socialShareProps,
                vertical: !!get(storySettings, ['verticalShare'], '')
              },
              cb
            )
          })
        }
      },
      { once: true }
    )
  }

  for (const storyCard of storyCards) {
    const cardId = get(storyCard, ['id'], '')
    const storyElements = get(storyCard, ['story-elements'], [])
    storyElements.forEach((storyElement, index) => {
      const storyElementId = get(storyElement, ['id'])
      let elementType = storyElement.subtype || storyElement.type || ''
      if (elementType === 'image-gallery') {
        elementType = get(storyElement, ['metadata', 'type'])
      }
      const targetId = storyElement.targetId
        ? `widget-${storyElement.targetId}-${storyId}`
        : `widget-${index}_${cardId}`
      const isAd = elementType === 'ad'
      const isWidget = elementType === 'widget'
      if (isAd || isWidget) {
        return renderComponent(isAd ? AdComponent : WidgetComp, isAd ? `ad-${index}_${cardId}` : targetId, store, {
          ...storyElement,
          hydrate: true
        })
      }

      if (elementType === 'slideshow') {
        return import('../isomorphic/components/image-slideshow').then(ImageSlideshowModule => {
          const ImageSlideshow = ImageSlideshowModule.default
          renderComponent(ImageSlideshow, `image-slideshow-${storyElementId}`, store, {
            element: storyElement,
            hydrate: true
          })
        })
      }

      if (elementType === 'gallery') {
        return import('../isomorphic/components/image-gallery').then(ImageGalleryModule => {
          const ImageGallery = ImageGalleryModule.default
          renderComponent(ImageGallery, `image-gallery-${storyElementId}`, store, {
            element: storyElement,
            hydrate: true
          })
        })
      }

      const supportedVideoTypes = ['video', 'youtube-video', 'dailymotion-video']
      if (supportedVideoTypes.includes(elementType) && storyElement.id !== heroVideo.id) {
        import('../isomorphic/components/video-element').then(VideoModule => {
          const Video = VideoModule.default
          renderComponent(Video, `video-${storyElementId}`, store, {
            element: storyElement,
            loadIframeOnClick: get(others, ['lazyLoadJsEmbed']) || true,
            hydrate: true
          })
        })
      }

      const supportedEmbedTypes = ['jsembed', 'tweet']
      if (supportedEmbedTypes.includes(elementType)) {
        const elementId = `jsembed-${storyElementId}`
        const timeoutCounter = setTimeout(() => {
          renderComponent(WithLazyTrigger, elementId, store, {
            onIntersection: async () => {
              const StoryElement = (await import('../isomorphic/components/story-element')).default
              renderComponent(StoryElement, elementId, store, {
                element: storyElement,
                story,
                hydrate: true
              })
            }
          })
          clearTimeout(timeoutCounter)
        }, 3000)
      }

      if (get(storyElement, ['subtype']) === 'facebook-post' && shouldRunFBMobileVideoFix) {
        facebookMobileVideoResizeFix()
        shouldRunFBMobileVideoFix = false
      }

      // figure out how to avoid this carpet bombing because of:
      // arrow/src/components/Molecules/StoryElementCard/index.js#L206
      const ignoreThese = [
        'ad',
        'widget',
        'text',
        'summary',
        'blurb',
        'blockquote',
        'quote',
        'bigfact',
        'also-read',
        'image',
        'q-and-a',
        'question',
        'answer',
        'gallery',
        'slideshow',
        'attachment',
        'references',
        'jsembed',
        'youtube-video',
        'dailymotion-video'
      ]

      if (!ignoreThese.includes(elementType) && document.getElementById(`element-${storyElementId}`)) {
        import('../isomorphic/components/story-element').then(StoryElementModule => {
          const StoryElement = StoryElementModule.default
          renderComponent(StoryElement, `element-${storyElementId}`, store, {
            element: storyElement,
            story,
            hydrate: true
          })
        })
      }
    })
  }

  if (customSlotAfterStory.type) {
    const targetId = customSlotAfterStory.targetId
      ? `widget-after-story-${customSlotAfterStory.targetId}-${storyId}`
      : `widget-after-story-${storyId}`

    const isAd = customSlotAfterStory.type === 'ad'
    hydrateSlotComponent(isAd ? AdComponent : WidgetComp, isAd ? `ad-after-story-${storyId}` : targetId, store, {
      ...customSlotAfterStory,
      hydrate: true
    })
  }

  if (document.getElementById('paywall-story')) {
    const isFacebookAppUserAgent = navigator.userAgent || navigator.vendor
    if (isFacebookAppUserAgent.indexOf('FBAN') > -1 || isFacebookAppUserAgent.indexOf('FBAV') > -1) {
      const { storyBaseType } = findStoryTemplate({ story, config: storeConfig })
      renderComponent(Story, 'paywall-story', store, {
        story,
        storyStoreField: 'story',
        storyPageType: 'text-story',
        adComponent: AdComponent,
        widgetComp: WidgetComp,
        storyBaseType,
        hydrate: true
      })
    }
  }

  // Hydrate StoryFooter
  // checking for id because this component is disabled during preview
  document.getElementById(`story-footer-${storyId}`) &&
    renderComponent(StoryFooter, `story-footer-${storyId}`, store, {
      story,
      hydrate: true
    })
}
