/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { connect } from "react-redux";
import get from "lodash/get";
import backArrowIcon from "../../../../assets/icons/back-arrow.svg";
import searchIcon from "../../../../assets/icons/search.svg";
import closeIcon from "../../../../assets/icons/social/close.svg";
import { SearchBox } from "../../basic/search-box";
import { SvgHandler } from "../../svg-handler/svg-handler";
import "./search.m.css";

const DrawForm = ({ children }) => {
  return (
    <div>
      <div styleName="internal-search-wrapper">
        <label htmlFor="searchForm" key="1">
          {children}
        </label>
      </div>
    </div>
  );
};

DrawForm.propTypes = {
  children: PropTypes.element,
};

const HeaderSearchBase = (props) => {
  const {
    dataTestId,
    toggleSearchForm,
    closeAll,
    hideSearchForm,
    header = {},
    headerTheme,
    localizedElementsData = {},
  } = props;
  const { searchLabels = {} } = localizedElementsData;
  const { placeholder: localizedPlaceholder } = searchLabels;
  const isSingleLayer = headerTheme.includes("single-layer");
  const headerType = isSingleLayer ? "single-layer" : headerTheme === "header_2" ? "header-two" : "";
  const isSearchFormVisible = header.isSearchFormVisible;
  const searchBoxRef = useRef(null);
  const [showClearButton, setShowClearButton] = useState(false);
  const searchFormEnabledStyle = isSearchFormVisible ? "highlight-search" : "";
  // the below code is required for the search box to appear on layer 1
  // styles can't be updated in header templates because its not hydrated
  if (!isSingleLayer) {
    const layerTwoClassList = document.getElementById("layer-2") && document.getElementById("layer-2").classList;
    if (layerTwoClassList) {
      isSearchFormVisible ? layerTwoClassList.add("show-search-form") : layerTwoClassList.remove("show-search-form");
    }
  }

  return (
    <div styleName={["search-wrapper", headerType].join(" ")}>
      <div
        data-test-id={dataTestId}
        styleName={["search-icon", searchFormEnabledStyle].join(" ")}
        onClick={toggleSearchForm}
      >
        <SvgHandler
          xlinkHref={searchIcon}
          clazzName="search"
          width="24"
          height="24"
          svgWrapperClazzName="svg-wrapper"
        />
      </div>
      {!isSearchFormVisible ? null : (
        <div>
          <button styleName="back-button" className="search-back-button" onClick={hideSearchForm}>
            <SvgHandler width="24" height="24" xlinkHref={backArrowIcon} />
          </button>
          <SearchBox
            ref={searchBoxRef}
            styleName={`search-form search-form--visible`}
            className={`header-search-form`}
            inputId="searchForm"
            inputClassName="search-form-input"
            placeholder={localizedPlaceholder || "Search Here"}
            onSubmitHandler={closeAll}
            onEscape={hideSearchForm}
            template={DrawForm}
            onChange={(value) => {
              if (showClearButton && value === "") {
                setShowClearButton(false);
              }
              if (!showClearButton && value !== "") {
                setShowClearButton(true);
              }
            }}
          />
          {showClearButton && (
            <button
              styleName="search-clear-btn"
              className="search-clear-btn"
              onClick={() => {
                searchBoxRef.current && searchBoxRef.current.clearText();
              }}
            >
              <SvgHandler xlinkHref={closeIcon} clazzName="close-icon" width="24" height="24" />
            </button>
          )}
        </div>
      )}
    </div>
  );
};
HeaderSearchBase.propTypes = {
  header: PropTypes.object,
  hideSearchForm: PropTypes.func,
  toggleSearchForm: PropTypes.func,
  closeAll: PropTypes.func,
  isSearchFormVisible: PropTypes.bool,
  dataTestId: PropTypes.string,
  headerTheme: PropTypes.string,
  localizedElementsData: PropTypes.shape({
    buttonLabels: PropTypes.shape({
      searchPlaceholder: PropTypes.string,
    }),
  }),
};

const mapStateToProps = (state) => {
  const { enableLocalization = false, localizedElements = {} } = get(
    state.qt,
    ["config", "pagebuilder-config", "general", "localization"],
    {}
  );
  return {
    headerTheme: get(state.qt, ["config", "pagebuilder-config", "header", "headerStyle"], "header_1"),
    header: state.header || {},
    localizedElementsData: enableLocalization ? localizedElements : {},
  };
};

const mapDispatchToProps = (dispatch) => ({
  hideSearchForm: () => dispatch({ type: "CLOSE_SEARCHFORM" }),
  toggleSearchForm: () => dispatch({ type: "TOGGLE_SEARCHFORM" }),
  closeAll: () => dispatch({ type: "CLOSE_ALL" }),
});

export const HeaderSearch = connect(mapStateToProps, mapDispatchToProps)(HeaderSearchBase);
export { HeaderSearchBase };
