/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import facebookSquare from "./svg/facebook-square.svg";
import instagramSquare from "./svg/instagram-square.svg";
import linkedinSquare from "./svg/linkedin-square.svg";
import twitterSquare from "./svg/twitter-square.svg";
import whatsappSquare from "./svg/whatsapp-square.svg";
import youtubeSquare from "./svg/youtube-square.svg";
import pinterestSquare from "./svg/pinterest-square.svg";
import telegramSquare from "./svg/telegram-square.svg";

export const SquareSocial = {
  facebook: facebookSquare,
  twitter: twitterSquare,
  instagram: instagramSquare,
  linkedin: linkedinSquare,
  youtube: youtubeSquare,
  whatsapp: whatsappSquare,
  pinterest: pinterestSquare,
  telegram: telegramSquare,
};
