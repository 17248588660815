/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";

import { SvgHandler } from "../../svg-handler/svg-handler";
import searchIcon from "../../../../assets/icons/search.svg";
import closeIcon from "../../../../assets/icons/social/close.svg";
import { SearchBox } from "../../basic/search-box";

import "./new-search.m.css";
import get from "lodash/get";

const DrawForm = ({ children }) => {
  return (
    <div key="1">
      <label htmlFor="searchForm">{children}</label>
    </div>
  );
};

DrawForm.propTypes = {
  children: PropTypes.element,
};

export const NewSearchBar = () => {
  const [showClearButton, setShowClearButton] = useState(false);
  const searchBoxRef = useRef(null);
  const dispatch = useDispatch();
  const { enableLocalization = false, localizedElements = {} } = useSelector((state) =>
    get(state, ["qt", "config", "pagebuilder-config", "general", "localization"], {})
  );
  const { searchLabels = {} } = enableLocalization ? localizedElements : {};
  const { placeholder: localizedSearchPlaceHolder } = searchLabels;

  const closeAll = () => {
    dispatch({ type: "CLOSE_ALL", isSearchFormVisible: false });
  };

  const hideSearchForm = () => {
    dispatch({ type: "CLOSE_SEARCHFORM", isSearchFormVisible: false });
  };

  return (
    <div styleName="search-wrapper" className="search-wrapper" data-testid="search">
      <div styleName="search-icon" className="search-icon" data-testid="search-button">
        <SvgHandler width="24px" height="24px" xlinkHref={searchIcon} />
      </div>
      <SearchBox
        ref={searchBoxRef}
        styleName="search-form"
        className="new-search-form"
        inputId="searchForm"
        inputClassName="search-form-input"
        placeholder={localizedSearchPlaceHolder || "Search Here"}
        onSubmitHandler={closeAll}
        onEscape={hideSearchForm}
        template={DrawForm}
        onChange={(value) => {
          if (showClearButton && value === "") {
            setShowClearButton(false);
          }
          if (!showClearButton && value !== "") {
            setShowClearButton(true);
          }
        }}
      />
      {showClearButton && (
        <div
          onClick={() => searchBoxRef.current && searchBoxRef.current.clearText()}
          styleName="clear-button"
          className="clear-button"
          data-testid="search-clear"
        >
          <SvgHandler width="18px" height="18px" xlinkHref={closeIcon} />
        </div>
      )}
    </div>
  );
};
