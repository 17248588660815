/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import { connect } from "react-redux";
import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import { SvgHandler } from "../../svg-handler/svg-handler";
import downArrowIcon from "../../../../assets/icons/down-arrow.svg";
import { MenuLink } from "../../menu-link.js";
import "./menu.m.css";

class MenuBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeChild: null,
      leftScroll: 0,
    };
    this.toggleSubmenu = this.toggleSubmenu.bind(this);
    this.toggleSubMenuForMobile = this.toggleSubMenuForMobile.bind(this);
    this.getLeftEdgeHoverValue = this.getLeftEdgeHoverValue.bind(this);
  }

  toggleSubmenu(e) {
    e.stopPropagation();
    e.preventDefault();
    const currentChild = Number(e.currentTarget.dataset.id);
    const sidebar = e.currentTarget.dataset.sidebar;
    if (sidebar) {
      this.setState(function (prevState) {
        return {
          activeChild: prevState.activeChild === currentChild ? null : currentChild,
        };
      });
    }
  }

  toggleSubMenuForMobile(e) {
    const currentChild = Number(e.currentTarget.dataset.id);
    this.toggleSubmenu(e);
    if (this.props.primarySubmenu !== currentChild) {
      return this.props.openPrimarySubmenu(currentChild);
    }
    return this.props.closePrimarySubmenu();
  }

  getMenuTypeLink(item, index, sidebar) {
    const showSubitemsMobile = sidebar && this.props.sidebar;

    if (item["item-type"] === "placeholder" || sidebar) {
      return (
        <div
          styleName="desktop-links"
          className="desktop-links"
          onClick={this.toggleSubmenu}
          data-id={index}
          data-sidebar={showSubitemsMobile}
        >
          {item.title}
        </div>
      );
    }
    return (
      <div styleName="desktop-links" className="desktop-links">
        <MenuLink callback={this.props.closeAll} href={item.completeUrl} item={item} />
      </div>
    );
  }

  getMenuTypeLinkForMobile(item, index) {
    return (
      <div
        styleName="mobile-links"
        className="mobile-links"
        onClick={this.toggleSubMenuForMobile}
        data-sidebar={this.props.sidebar}
        data-id={index}
      >
        {item.title}
      </div>
    );
  }

  getLeftEdgeHoverValue(e) {
    const rectValue = e.currentTarget.getBoundingClientRect();
    const scrollValue = get(rectValue, ["left"], 0);
    scrollValue && this.setState({ leftScroll: scrollValue });
  }

  render() {
    const {
      menu,
      sidebar,
      hasMoreItems,
      languageDirection = "ltr",
      primarySubmenu,
      closeAll,
      customCssClasses = "",
      type,
      isSingleLayer,
    } = this.props;
    const singleLayerSelector = isSingleLayer ? "single-layer-" : "";
    const menuClass = sidebar ? "" : `${singleLayerSelector}header-${type}`;
    const subMenuPosition = { left: this.state.leftScroll };
    const { enableDate = false, enableTime = false } = this.props.dateTime || {};
    const customStyle = enableDate || enableTime ? "" : "menu-custom-style";
    if (menu && menu.length < 1) {
      return null;
    }
    return (
      <ul
        data-menu="true"
        className={`menu-wrapper ${customStyle}`}
        styleName={`menu ${customCssClasses} ${menuClass} top-level-menu ${
          primarySubmenu !== null ? "primary-menu-scroll" : ""
        }  ${type === "default" ? "primary-menu-override" : ""}`}
        style={{ position: `${subMenuPosition?.left === 0 ? "relative" : "static"}` }}
      >
        {menu.map((item, index) => {
          if (item.children.length && item["menu-group-slug"] !== "secondary-menu") {
            return (
              <li
                data-id={index}
                styleName={`menu-item has-child ${index === this.state.activeChild ? "submenu-open" : ""} ${
                  index === primarySubmenu ? "horizontal-submenu" : ""
                }`}
                key={`${item.id}${index}`}
                onMouseEnter={(e) => this.getLeftEdgeHoverValue(e)}
              >
                {this.getMenuTypeLinkForMobile(item, index, sidebar)}
                {this.getMenuTypeLink(item, index, sidebar)}
                {sidebar && (
                  <span
                    styleName="arrow"
                    data-sidebar={this.props.sidebar}
                    onClick={this.toggleSubmenu}
                    data-id={index}
                  >
                    <SvgHandler width="24px" height="24px" xlinkHref={downArrowIcon} />
                  </span>
                )}
                {sidebar && (
                  <span
                    styleName="mobile-arrow"
                    data-sidebar={this.props.sidebar}
                    onClick={this.toggleSubMenuForMobile}
                    data-id={index}
                  >
                    <SvgHandler width="24px" height="24px" xlinkHref={downArrowIcon} />
                  </span>
                )}
                <ul
                  style={subMenuPosition}
                  data-submenu="true"
                  styleName={`sub-menu ${index === this.state.activeChild ? "open" : "close"} ${
                    languageDirection === "rtl" ? "sub-menu-rtl" : ""
                  } ${index === primarySubmenu ? "open-submenu" : ""}`}
                >
                  {item["item-type"] === "placeholder" || !sidebar ? null : (
                    <li styleName="sub-menu-placeholder">
                      <MenuLink callback={closeAll} item={item} />
                    </li>
                  )}
                  {item.children.map((child, index) => {
                    return (
                      <li styleName="sub-menu-item" key={`${child.id}${index}`}>
                        <MenuLink callback={closeAll} item={child} href={child.url} />
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          }

          return (
            <li styleName="menu-item" key={`${item.id}${index}`}>
              {item["item-type"] === "placeholder" ? (
                <span>{item.title}</span>
              ) : (
                <MenuLink callback={closeAll} item={item} />
              )}
            </li>
          );
        })}
        {hasMoreItems && (
          <li styleName="menu-item has-child">
            More
            <ul styleName="sub-menu">
              {menu.map((item, index) => (
                <li styleName="sub-menu-item" key={`${item.id}${index}`}>
                  <MenuLink callback={closeAll} item={item} href={item.url} />
                </li>
              ))}
            </ul>
          </li>
        )}
      </ul>
    );
  }
}

MenuBase.propTypes = {
  item: PropTypes.object,
  menu: PropTypes.array,
  sidebar: PropTypes.bool,
  hasMoreItems: PropTypes.bool,
  languageDirection: PropTypes.string,
  primarySubmenu: PropTypes.number,
  openPrimarySubmenu: PropTypes.func,
  closePrimarySubmenu: PropTypes.func,
  closeAll: PropTypes.func,
  customCssClasses: PropTypes.string,
  type: PropTypes.string,
  isSingleLayer: PropTypes.bool,
};

const mapStateToProps = (state) => {
  return {
    isSidebarVisible: get(state, ["header", "isSidebarVisible"]),
    primarySubmenu: get(state, ["header", "primarySubmenu"]),
    languageDirection: get(state, ["qt", "config", "language", "direction"], "ltr"),
    dateTime: get(state, ["qt", "config", "pagebuilder-config", "header", "dateTime"], {}),
  };
};

const mapDispatchToProps = (dispatch) => ({
  openPrimarySubmenu: (menuId) => dispatch({ type: "OPEN_PRIMARY_SUBMENU", payload: { menuId } }),
  closePrimarySubmenu: () => dispatch({ type: "CLOSE_PRIMARY_SUBMENU" }),
  closeAll: () => dispatch({ type: "CLOSE_ALL" }),
});

export const Menu = connect(mapStateToProps, mapDispatchToProps)(MenuBase);
