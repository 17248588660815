/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import { AdbutlerAd, createDfpAdComponent, refreshDfpAds } from "@quintype/components";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { decodeCustomCode } from "../../utils/decodeCustomCode";
import { shouldDeferAdsBy } from "../../utils/should-defer-ads-by";
import { sizes } from "../adbutler-ad-config";
import { dfpAdConfig } from "../dfp-ad-config";
import { replaceScriptNodes } from "../utils";
import "./ad-service.module.css";
import { AdWrapper } from "./ad-wrapper";
import { ErrorBoundary } from "./error-boundary";

class AdServiceBase extends React.Component {
  constructor(props) {
    super(props);
    this.othersAdRef = React.createRef();
  }

  componentDidMount = () => {
    // TODO: No need to do this unless you've PB config.
    const currentNode = this.othersAdRef.current;
    currentNode && replaceScriptNodes(currentNode);
    if (this.props.shouldRefreshOnce && this.props.slotId) {
      setTimeout(() => {
        refreshDfpAds(this.props.slotId);
      }, 500);
    }
  };

  render() {
    const {
      disableAds,
      layoutName = "",
      collectionSlug = "",
      adtype = "",
      adTargetingId = "",
      member,
      memberSubscriptions,
      pbConfig,
      row,
      customCode,
      slotId,
      deferAdsBy,
    } = this.props;

    const adService = "dfp";
    const adbutlerConfig = {};
    const lazyLoadDfpAds = get(pbConfig, ["general", "ads", "lazyLoadDfp"], true);
    const { dfpNetworkId: pbDfpNetworkId, deferAds = true } = get(pbConfig, ["general", "ads"], {});
    const otherAdsScripts = get(pbConfig, ["general", "otherAdsScripts"], {});
    const hasId = (adService === "dfp" && pbDfpNetworkId) || (adService === "adbutler" && adbutlerConfig.publisherId);
    if (!pbConfig && (disableAds || !hasId)) {
      return null;
    }

    const adbutlerAdProps = {
      adtype,
      adbutlerConfig,
      sizes,
    };
    const getActivePlan = () => memberSubscriptions.find((plan) => plan.active);
    const dfpAdProps = {
      defaultNetworkID: pbDfpNetworkId,
      config: dfpAdConfig,
      slotId,
      targeting: (state) => {
        const { pageType = "", config = {}, data = {} } = get(state, ["qt"], {});
        const { "publisher-name": publisherName, "publisher-id": publisherId, env: environment } = config;
        const sectionName = get(data, ["section", "name"]);
        const storyId = get(data, ["story", "id"]);
        const sponsoredBy = get(data, ["story", "metadata", "sponsored-by"]);

        return {
          pageType,
          adTargetingId,
          publisherName,
          publisherId,
          environment,
          hasSubscription: !!(member && getActivePlan()),
          ...(sectionName && { sectionName }),
          ...(storyId && { storyId }),
          ...(sponsoredBy && { sponsoredBy }),
          ...(layoutName && { layoutName }),
          ...(collectionSlug && { collectionSlug }),
        };
      },
      lazyLoad: lazyLoadDfpAds,
      deferAdsBy: shouldDeferAdsBy(deferAds, deferAdsBy),
    };

    const DfpAdComponent = createDfpAdComponent(dfpAdProps);
    // We create a fixed placeholder for the ads to avoid layout shift and improve CLS score
    const DfpAd = (props) => {
      if (dfpAdConfig[props.adtype]) {
        return (
          <AdWrapper adLayout={props.adtype}>
            <DfpAdComponent {...props} />
          </AdWrapper>
        );
      }
      return <DfpAdComponent {...props} />;
    };

    const returnRowAd = () => {
      if (adtype === "Custom")
        return (
          <ErrorBoundary>
            <div
              styleName="othersWrapper"
              ref={this.othersAdRef}
              dangerouslySetInnerHTML={{ __html: decodeCustomCode(customCode) }}
            />
          </ErrorBoundary>
        );
      if (pbDfpNetworkId) return <DfpAd {...this.props} />;
      return null;
    };

    const returnAd = () => {
      if (pbDfpNetworkId) return <DfpAd {...this.props} />;
      if (adService === "adbutler") return <AdbutlerAd {...adbutlerAdProps} />;
      if (otherAdsScripts[adtype])
        return (
          <ErrorBoundary>
            <div
              styleName="othersWrapper"
              ref={this.othersAdRef}
              dangerouslySetInnerHTML={{ __html: decodeCustomCode(otherAdsScripts[adtype]) }}
            />
          </ErrorBoundary>
        );
      return null;
    };

    if (pbConfig && row) return returnRowAd();
    if (pbConfig) return returnAd();

    switch (adService) {
      case "dfp":
        return <DfpAd {...this.props} />;
      case "adbutler":
        return <AdbutlerAd {...adbutlerAdProps} />;
    }
  }
}

AdServiceBase.propTypes = {
  disableAds: PropTypes.bool,
  layoutName: PropTypes.string,
  collectionSlug: PropTypes.string,
  adtype: PropTypes.string,
  adTargetingId: PropTypes.string,
  member: PropTypes.object,
  memberSubscriptions: PropTypes.array,
  pbConfig: PropTypes.object,
  row: PropTypes.bool,
  customCode: PropTypes.string,
  slotId: PropTypes.string,
  shouldRefreshOnce: PropTypes.bool,
  deferAdsBy: PropTypes.number,
};

const mapStateToProps = (state) => {
  return {
    pbConfig: get(state, ["qt", "config", "pagebuilder-config"]),
    disableAds: get(state, ["qt", "config", "disable-ads"]),
    member: get(state, ["member"], null),
    memberSubscriptions: get(state, ["memberSubscriptions"], []),
    deferAdsBy: get(state, ["qt", "config", "deferAdsBy"]),
  };
};

export const AdService = connect(mapStateToProps)(AdServiceBase);
