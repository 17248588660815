/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { dateFormatter, timeFormatter } from "../../../utils";
import "./current-date-time.m.css";

export const CurrentDateTime = () => {
  const { language = {}, "pagebuilder-config": pbConfig = {} } = useSelector((state) =>
    get(state, ["qt", "config"], {})
  );

  const langCode = get(language, ["ietf-code"], "en");
  const { header = {}, general = {} } = pbConfig;
  const { enableTime = false, enableDate = false, dateFormat = "mdy" } = get(header, ["dateTime"], {});

  const { currentDate = "" } = dateFormatter(langCode, dateFormat);
  const { currentTime = "" } = timeFormatter(general.timeFormat);
  const [realTime, updateCurrentTime] = useState(currentTime);

  useEffect(() => {
    const interval = setInterval(() => {
      const { currentTime = "" } = timeFormatter(general.timeFormat);
      updateCurrentTime(currentTime);
    }, 60000);
    return () => clearInterval(interval);
  }, []);

  if (!enableDate && !enableTime) return null;

  return (
    <div styleName="wrapper">
      {enableDate && (
        <span styleName="current-date" data-testid="current-date">
          {currentDate}
        </span>
      )}
      {enableTime && (
        <span styleName="current-time" data-testid="current-time">
          {realTime}
        </span>
      )}
    </div>
  );
};
