/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
export const PAGE_TYPE = Object.freeze({
  HOME_PAGE: "home-page",
  SECTION_PAGE: "section-page",
  TAG_PAGE: "tag-page",
  AUTHOR_PAGE: "author-page",
  AUTHORS_PAGE: "authors-page",
  SEARCH_PAGE: "search-page",
  STORY_PAGE: "story-page",
  STORY_PUBLIC_PREVIEW_PAGE: "story-public-preview-page",
  STORY_PREVIEW: "story-preview-page",
  HOME_PREVIEW: "home-preview-page",
  STATIC_PAGE: "static-page",
  COLLECTION_PAGE: "collection-page",
  VISUAL_STORY: "visual-story",
  USER_DETAILS: "user-details",
  USER_LOGIN: "user-login",
  SUBSCRIPTION: "subscription",
  MANAGE_SUBSCRIPTIONS: "manage-subscriptions",
  AVAILABLE_PLANS: "available-plans",
  PLAN_CHANGE_OVERVIEW: "plan-change-overview",
  ISSUE_LANDING_PAGE: "issue-landing-page",
  MAGAZINE_LANDING_PAGE: "magazine-landing-page",
  MAGAZINE_ARCHIVE_PAGE: "magazine-archive-page",
  PB_COLLECTION_PREVIEW_PAGE: "pagebuilder-collection-preview-page",
  PB_STORY_PREVIEW_PAGE: "pagebuilder-story-preview-page",
  PB_SECTION_PREVIEW_PAGE: "pagebuilder-section-preview-page",
  PB_AUTHORS_PREVIEW_PAGE: "pagebuilder-authors-preview-page",
  PB_AUTHOR_PREVIEW_PAGE: "pagebuilder-author-preview-page",
  PB_TAG_PREVIEW_PAGE: "pagebuilder-tag-preview-page",
  PB_MAGAZINE_ARCHIVE_PREVIEW_PAGE: "pagebuilder-magazine-archive-preview-page",
  PB_MAGAZINE_LANDING_PREVIEW_PAGE: "pagebuilder-magazine-landing-preview-page",
  PB_MAGAZINE_ISSUE_PREVIEW_PAGE: "pagebuilder-magazine-issue-preview-page",
  PB_SEARCH_PREVIEW_PAGE: "pagebuilder-search-preview-page",
  SUBSCRIPTION_SUCCESS: "subscription-success",
  SIGN_IN: "sign-in",
  FORGOT_PASSWORD: "forgot-password",
  SIGN_UP: "sign-up",
  PURCHASED_DETAILS: "purchased-details",
});

export const WHITELIST_CONFIG_KEYS = [
  "cdn-image",
  "sketches-host",
  "polltype-host",
  "publisher-name",
  "publisher-id",
  "env",
  "publisher-settings",
  "social-links",
  "static-pages",
  "layout",
  "sections",
  "public-integrations",
  "language",
  "brightcove",
];

export const DEFAULT_FETCH_PARAM = {
  method: "POST",
  mode: "same-origin",
  cache: "no-cache",
  credentials: "same-origin",
  headers: {
    "Content-Type": "application/json",
  },
};

export const STORY_FIELDS =
  "alternative,slug,metadata,story-template,story-content-id,id,headline,hero-image-s3-key,hero-image-metadata,sections,tags,author-name,author-id,authors,created-at,first-published-at,published-at,last-published-at,url,subheadline,read-time,access,hero-image-caption,hero-image-alt-text";

export const SEARCH_AGGREGATION = "story-template,author-name,section-name";

export const LOAD_MORE_LIMIT = 10;

export const DEFAULT_AMAZON_POLLY_CAPTION = "Press play to listen to this story";

export const SLIDER_ANIMATION_DURATION = 1500;

export const ATTACHMENT_TYPE = { PDF: "application/pdf" };

export const SEARCH_RESULTS_LIMIT = 10;

export const SINGLE_LAYER_ROOT_MARGIN = "56px 0px 0px 0px";

export const DOUBLE_LAYER_ROOT_MARGIN = "72px 0px 0px 0px";

export const WHITELIST_MOBILE_CONFIG = (pageType) => {
  switch (pageType) {
    case "config":
      return ["cdn-image"];

    case PAGE_TYPE.HOME_PAGE:
    case PAGE_TYPE.SECTION_PAGE:
    case PAGE_TYPE.COLLECTION_PAGE:
      return {
        collection: ["slug", "name", "summary", "total-count", "items", "metadata"],
        navigationMenu: [
          "menu-group-slug",
          "title",
          "item-type",
          "section-slug",
          "tag-slug",
          "url",
          "children",
          "completeUrl",
          "isExternalLink",
          "section-name",
          "data",
        ],
        section: "all",
      };

    case PAGE_TYPE.STORY_PAGE:
      return {
        relatedStories: "all",
        story: [
          "updated-at",
          "seo",
          "assignee-id",
          "author-name",
          "tags",
          "headline",
          "storyline-id",
          "votes",
          "story-content-id",
          "slug",
          "last-published-at",
          "subheadline",
          "alternative",
          "sections",
          "story-audio",
          "read-time",
          "access-level-value",
          "content-created-at",
          "owner-name",
          "custom-slug",
          "push-notification",
          "publisher-id",
          "hero-image-metadata",
          "comments",
          "word-count",
          "entities",
          "published-at",
          "is-live-blog",
          "breaking-news-linked-story-id",
          "storyline-title",
          "summary",
          "push-notification-title",
          "external-id",
          "canonical-url",
          "is-amp-supported",
          "autotags",
          "linked-entities",
          "status",
          "hero-image-attribution",
          "bullet-type",
          "id",
          "hero-image-alt-text",
          "hero-image-s3-key",
          "contributors",
          "associated-series-collection-ids",
          "cards",
          "url",
          "story-version-id",
          "content-type",
          "content-updated-at",
          "author-id",
          "owner-id",
          "linked-stories",
          "linked-story-ids",
          "access",
          "promotional-message",
          "asana-project-id",
          "first-published-at",
          "hero-image-caption",
          "version",
          "story-template",
          "sequence-no",
          "created-at",
          "authors",
          "metadata",
          "publish-at",
          "assignee-name",
        ],
      };

    case PAGE_TYPE.AUTHOR_PAGE:
      return {
        author: ["slug", "name", "avatar-url", "bio", "id", "avatar-s3-key", "twitter-handle", "stats", "metadata"],
        stories: "all",
      };

    case PAGE_TYPE.TAG_PAGE:
      return {
        tag: "all",
        tagName: "all",
        tagDescription: "all",
        tagSlug: "all",
        stories: "all",
      };
  }
};

export const SHOW_MEMBER_METADATA_POPUP = "SHOW_MEMBER_METADATA_POPUP";
export const MEMBER_METADATA_UPDATED = "MEMBER_METADATA_UPDATED";
export const MEMBER_METADATA = "MEMBER_METADATA";
export const UPDATE_SIGN_IN = "UPDATE_SIGN_IN";
export const UPDATE_SIGN_UP = "UPDATE_SIGN_UP";
export const UPDATE_FORGOT_PASSWORD = "UPDATE_FORGOT_PASSWORD";
export const UPDATE_SINGLE_SIGN_ON = "UPDATE_SINGLE_SIGN_ON";

export const headerFive = "header_5";
export const headerSix = "header_6";
export const footerTwo = "footer_2";
export const footerThree = "footer_3";

/* Custom event */

export const QT_PAGE_DATA = "qt-page-data";
export const defaultPremiumIconColor = "#F7B500";
export const defaultTheme = "#ffffff";
export const defaultDarkTheme = "#1d1d1d";

export const SKIP_CANONICAL = "__SKIP__CANONICAL__";
export const DATA_LIMIT = 160;

export const MADRID_ONLY_PATHS = [
  "/quintype-ace/",
  "/favicon.ico",
  "/qlitics.js",
  "/user-details",
  "/user-login",
  "/subscription",
  "/magazine/",
  "/amp/",
  "/ampstories/",
  "/route-data.json",
  "/mobile-data.json",
  "/subscription-success",
  "/sign-in",
  "/sign-up",
  "/forgot-password",
  "/manage-subscriptions",
  "/available-plans",
  "/plan-change-overview",
  "/purchased-stories",
];

export const NEXT_ONLY_PATHS = ["/_next/"];

export const ROUTES_TO_HANDLE = ["*"];


export const HEADERS_TO_IGNORE = {'vary': true};

// login calls

export const SEND_OTP_API = "/api/auth/v1/users/send-otp";
export const LOGIN_API = "/api/auth/v1/login";
export const LOGIN_PROXY_API = "/api/proxy/v1/login";
export const EMAIL_CHECK_API = "/api/auth/v1/users/email/check?email=";
export const SIGN_UP_PROXY_API = "/api/proxy/v1/signup";
export const SIGN_UP_API = "/api/auth/v1/signup";
export const UPDATE_WITH_OTP_API = "/api/auth/v1/users/update-with-otp";
export const RESET_PASSWORD_API = "/api/auth/v1/users/reset-password";

export const QUILL_EMPTY_STRING = "<p><br></p>";
export const defaultMeteringSingularMessage = "You can still access [number-of-stories] more article for free";
export const defaultMeteringPluralMessage = "You can still access [number-of-stories] more articles for free.";

// default login variables

export const SIGN_IN = "Sign in";
export const EMAIL_LABEL = "Enter Email";
export const CREATE_PASSWORD = "Create Password";
export const ENTER_NAME = "Enter Name";
export const LOGIN_LABEL = "Sign in";
export const ALREADY_HAVE_ACCOUNT = "Already have an account?";
export const SIGN_UP_HEADLINE = "Create an Account";
export const EMAIL_OTP_HEADLINE = "Enter the OTP sent to [email]";
export const ENTER_OTP = "Enter OTP";
export const SIGN_IN_HEADLINE = "Sign in or Create an Account";
export const ENTER_EMAIL_PHONE_NUMBER = "Enter Email or Phone Number";
export const DEFAULT_COUNTRY_CODE = "+1";
export const OR_LABEL = "Or";
export const ENTER_PASSWORD_TITLE = "Enter the Password for [email]";
export const ENTER_PASSWORD = "Enter Password";
export const MOBILE_OTP_HEADLINE = "Enter the OTP sent to [phone-number]";
export const TERMS_AND_CONDITIONS = "Terms and Conditions";
export const PRIVACY_POLICY = "Privacy Policy";
export const RESEND_OTP = "Resend OTP";
export const RESET_PASSWORD_TITLE = "Reset Password";
export const RESET_PASSWORD_SUBTITLE = "Enter the OTP sent to your email address & create a new password.";
export const CREATE_NEW_PASSWORD = "Create New Password";
export const FORGOT_PASSWORD_TITLE = "Forgot Password";
export const FORGOT_PASSWORD_LINK = "Forgot Password?";
export const PROFILE_PAGE_HEADLINE = "My Profile";

export const VERIFY_SIGN_IN_BUTTON = "Verify & Sign in";
export const CONTINUE_BUTTON = "Continue";
export const GO_BACK_BUTTON = "Go Back";
export const GET_OTP_BUTTON = "Get OTP";

export const SIGN_IN_SUCCESS = "Sign in successful.";
export const PASSWORD_RESET_SUCCESS = "Password reset successful.";
export const SIGN_UP_SUCCESS = "Sign up successful.";

export const CONTINUE_WITH_GOOGLE = "Continue with Google";
export const CONTINUE_WITH_FACEBOOK = "Continue with Facebook";
export const CONTINUE_WITH_LINKEDIN = "Continue with LinkedIn";

export const INVALID_EMAIL_ADDRESS_ERROR = "Please enter a valid email address.";
export const INVALID_PHONE_NUMBER_ERROR = "Please enter a valid phone number.";
export const GENERIC_ERROR = "Something went wrong, please try after sometime.";
export const INVALID_OTP_ERROR = "OTP entered is invalid.";
export const INVALID_PASSWORD_ERROR = "Password entered is invalid.";
export const EMAIL_EXISTS_ERROR = "Email already exists";

export const ACE_STAGING_HOST = "https://ace-web.qtstage.io";

export const PAGES_WITH_AUTH_HEADER = [
  "sign-in",
  "sign-up",
  "forgot-password",
  "subscription",
  "user-login",
  "user-details",
  "purchased-details",
];

export const STAGING_AT_HOST = "https://staging.accesstype.com";
export const PROD_AT_HOST = "https://www.accesstype.com";
