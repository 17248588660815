/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";

import { SvgHandler } from "../../svg-handler/svg-handler";
import darkModeIcon from "../../../../assets/icons/dark-mode.svg";
import lightModeIcon from "../../../../assets/icons/light-mode.svg";

import "./dark-mode-toggle.m.css";

export const DarkModeToggle = () => {
  const dispatch = useDispatch();
  const pbEnableDarkMode = useSelector((state) =>
    get(state, ["qt", "config", "pagebuilder-config", "header", "showDarkModeToggle"], false)
  );

  const isDarkModeEnabled = useSelector((state) => {
    return get(state, ["header", "isDarkModeEnabled"], false);
  });

  const [showDarkMode, setShowDarkMode] = useState(isDarkModeEnabled);
  const enableDarkMode = () => {
    setShowDarkMode(!showDarkMode);
    const date = new Date();
    date.setTime(date.getTime() + 31 * 24 * 60 * 60 * 1000);
    const expires = date.toGMTString();
    global.document.cookie = `enableDarkMode=${!showDarkMode};path=/;expires=${expires}`;
    dispatch({ type: "TOGGLE_DARK_MODE", isDarkModeEnabled: !showDarkMode });

    if (global && global.CustomEvent) {
      const customEvent = new CustomEvent("enable-dark-mode", {
        detail: { isDarkModeEnabled: !showDarkMode },
      });
      global.document.dispatchEvent(customEvent);
    }
    window?.googletag?.pubads()?.refresh();
  };

  if (!pbEnableDarkMode) {
    return null;
  }

  return (
    <div styleName="wrapper">
      {showDarkMode ? (
        <div styleName="toggle-button" className="dark-mode-toggle-btn" onClick={enableDarkMode} title="Light Mode">
          <SvgHandler
            svgWrapperClazzName="wrapper-mode-icon"
            xlinkHref={lightModeIcon}
            clazzName="light-mode-icon"
            width="24px"
            height="24px"
          />
        </div>
      ) : (
        <div styleName="toggle-button" className="dark-mode-toggle-btn" onClick={enableDarkMode} title="Dark Mode">
          <SvgHandler
            svgWrapperClazzName="wrapper-mode-icon"
            xlinkHref={darkModeIcon}
            clazzName="dark-mode-icon"
            width="24px"
            height="24px"
          />
        </div>
      )}
    </div>
  );
};
