/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from 'react';
import { CustomLink } from '../custom-link';
import get from 'lodash/get';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { AccessType } from '@quintype/components';
import LogedinUser from '../../../assets/icons/logedin-user-icon.svg';
import { SvgHandler } from '../svg-handler/svg-handler';
import { textWithFallback } from '../pages/subscription/helpers';
import './user-profile.m.css';
import { getPath } from '../../utils';

class UserDataBase extends React.Component {
  constructor (props) {
    super(props);
    this.state = {
      showUserData: false,
      loadingSubscriptionPlans: false
    };
    this.toggleUserData = this.toggleUserData.bind(this);
  }

  componentDidUpdate () {
    if (
      this.props.memberSubscriptions &&
      this.props.memberSubscriptions.length === 0 &&
      !this.state.loadingSubscriptionPlans
    ) {
      this.getUserSubscriptions();
    }
  }

  getUserSubscriptions = async () => {
    this.setState({ loadingSubscriptionPlans: true });
    const { subscriptions } = await this.props.getSubscriptionForUser();
    this.props.updateMemberSubscriptions(subscriptions);
    this.props.memberSubscriptionsLoaded();
    this.setState({ subscriptions });
  };

  toggleUserData = () => {
    this.setState({ showUserData: !this.state.showUserData });
  };

  async logoutHandler (event) {
    event.persist();
    event.preventDefault();
    event.stopPropagation();
    const sketchesHost = get(this.props, ["config", "sketches-host"], '');
    try {
      const url = getPath(sketchesHost, "/api/auth/v1/logout");
       await fetch(url).then(() => {
        global.talktype && global.talktype.accountUserLogout();
        this.props.updateMember(null);
        window.location.reload();
      });
    } catch (err) {
      return console.log("Logout error: ", err);
    }
  }

  fallbackImage () {
    const image = get(this.props, ['member', 'avatar-url']);
    if (image) {
      return <img styleName='avatar' src={image} loading='lazy' alt='author-avatar' />;
    }
    return (
      <span>
        <SvgHandler xlinkHref={LogedinUser} clazzName='author-icon' width='24' height='24' viewBox='0 0 24 24' />
      </span>
    );
  }

  render () {
    const accesstypeEnabled = get(
      this.props,
      ['config', 'pagebuilder-config', 'general', 'accesstypeIntegration', 'enableAccesstype'],
      false
    );
    const enablePayPerAssetEnabled = get(
      this.props,
      ['config', 'pagebuilder-config', 'general', 'accesstypeIntegration', 'enablePayPerAsset'],
      false
    );
    const { enableLocalization = false, localizedElements = {} } = get(
      this.props,
      ['config', 'pagebuilder-config', 'general', 'localization'],
      {}
    );
    const manageSubscriptionsText = get(
      this.props,
      ['config', 'pagebuilder-config', 'manage-subscriptions', 'pageSetting', 'manageSubscriptionsDropdownText'],
      'Manage Subscriptions'
    );
    const profilePageText = get(
      this.props,
      ['config', 'pagebuilder-config', 'profilePage', 'pageSetting', 'dropdownProfileText'],
      'My Profile'
    );
    const purchasedPageText = get(
      this.props,
      ['config', 'pagebuilder-config', 'purchasedStoryPage', 'pageSetting', 'dropdownPurchasedText'],
      'Purchased Stories'
    );
    const sketchesHost = get(this.props, ['config', 'sketches-host'], '');
    const { signInSignUp = {} } = enableLocalization ? localizedElements : {};
    const { signOut: localizedSignOut } = signInSignUp;
    const manageSubsLink =
      window?.location?.pathname === '/manage-subscriptions'
        ? getPath(sketchesHost, '/manage-subscriptions')
        : getPath(sketchesHost, `/manage-subscriptions?redirectUrl=${window?.location?.pathname}`);
    return (
      <>
        <div
          data-testid='user-details'
          onClick={this.toggleUserData}
          styleName={`user-details ${this.props.customCssClasses}`}
          className='login-user-details'
        >
          {this.fallbackImage()}
          {this.state.showUserData && (
            <div styleName='overlay'>
              <div styleName='user-data' className='user-data'>
                <ul>
                  <li>
                    <CustomLink href={getPath(sketchesHost, '/user-details')} callback={this.toggleUserData}>
                      {profilePageText || 'My Profile'}
                    </CustomLink>
                  </li>
                  {accesstypeEnabled && (
                    <>
                      {enablePayPerAssetEnabled && (
                        <li>
                          <CustomLink href={getPath(sketchesHost, '/purchased-stories')} callback={this.toggleUserData}>
                            {purchasedPageText || 'Purchased Stories'}
                          </CustomLink>
                        </li>
                      )}
                      <li>
                        <CustomLink href={manageSubsLink}>
                          {textWithFallback(manageSubscriptionsText, 'Manage Subscriptions')}
                        </CustomLink>
                      </li>
                    </>
                  )}
                  <li>
                    <div>
                      <a href={'#'} onClick={event => this.logoutHandler(event)}>
                        {localizedSignOut || 'Sign out'}
                      </a>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
function UserDataTemplate (props) {
  const email = get(props, ['member', 'email']);
  const phone = get(props, ['member', 'metadata', 'phone-number'], '1234');
  const PbAccessTypeConfig = get(props, ['config', 'pagebuilder-config', 'general', 'accesstypeIntegration'], {});
  const isStaging = get(PbAccessTypeConfig, ['accesstypeMode']) === 'staging';
  const {
    accesstypeKey = '',
    enableAccesstype = false,
    accessTypeProdHost = '',
    accessTypeStgHost = '',
    bridgeKeeperIntegrationId = ''
  } = PbAccessTypeConfig;
  const sketchesHost = get(props, ["config", "sketches-host"], '');
  const storyId = get(props, ['story', 'id']);
  return (
    <AccessType
      enableAccesstype={enableAccesstype}
      isStaging={isStaging}
      accessTypeKey={accesstypeKey} // "9rm1ijqQmGfJ2kwQ9zt2gWhK"
      accessTypeBkIntegrationId={bridgeKeeperIntegrationId}
      email={email}
      sketchesHost={sketchesHost}
      phone={phone}
      storyId={storyId}
      prodHost={accessTypeProdHost}
      stagingHost={accessTypeStgHost}
    >
      {({ getSubscriptionForUser }) => <UserDataBase getSubscriptionForUser={getSubscriptionForUser} {...props} />}
    </AccessType>
  );
}

UserDataBase.propTypes = {
  member: PropTypes.object,
  logout: PropTypes.func,
  subscriptions: PropTypes.array,
  getSubscriptionForUser: PropTypes.func,
  updateMemberSubscriptions: PropTypes.func,
  memberSubscriptions: PropTypes.array,
  customCssClasses: PropTypes.string,
  updateMember: PropTypes.func
};

const mapStateToProps = state => {
  return {
    config: get(state, ['qt', 'config']),
    member: get(state, ['member'], null),
    subscriptions: get(state, ['subscriptions'], []),
    memberSubscriptions: get(state, ['memberSubscriptions'], [])
  };
};

const mapDispatchToProps = dispatch => ({
  updateMemberSubscriptions: subscriptions => dispatch({ type: 'MEMBER_SUBSCRIPTIONS_UPDATED', subscriptions }),
  memberSubscriptionsLoaded: () => dispatch({ type: 'MEMBER_SUBSCRIPTIONS_LOADED' }),
  updateMember: member => dispatch({ type: 'MEMBER_UPDATED', member })
});

export const UserData = connect(mapStateToProps, mapDispatchToProps)(UserDataTemplate);
