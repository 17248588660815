/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { connect } from "react-redux";
import { Menu } from "./index";
import PropTypes from "prop-types";
import get from "lodash/get";
import { getMenuData } from "../header-helper-components";

const DefaultMenuBase = ({
  menu,
  domainSlug,
  languageDirection = "ltr",
  closeAll,
  customCssClasses = "",
  isSingleLayer,
}) => {
  const getMenuGroup = domainSlug ? "header-menu" : "default";
  return (
    <Menu
      menu={getMenuData(menu, getMenuGroup, domainSlug)}
      languageDirection={languageDirection}
      closeAll={closeAll}
      customCssClasses={`menu-row ${customCssClasses}`}
      type="default"
      isSingleLayer={isSingleLayer}
    />
  );
};

const mapStateToProps = (state) => {
  const headerTheme = get(state.qt, ["config", "pagebuilder-config", "header", "headerStyle"], "header_1");
  const isSingleLayer = headerTheme.includes("single-layer");

  return {
    menu: get(state.qt, ["data", "navigationMenu"], []),
    domainSlug: get(state.qt, ["config", "domainSlug"], ""),
    isSingleLayer,
    languageDirection: get(state.qt, ["config", "language", "direction"], "ltr"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeAll: () => dispatch({ type: "CLOSE_ALL" }),
  };
};

DefaultMenuBase.propTypes = {
  menu: PropTypes.array,
  domainSlug: PropTypes.string,
  closeAll: PropTypes.func,
  languageDirection: PropTypes.string,
  customCssClasses: PropTypes.string,
  isSingleLayer: PropTypes.bool,
  type: PropTypes.string,
};

export const DefaultMenu = connect(mapStateToProps, mapDispatchToProps)(DefaultMenuBase);
