/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import facebookPlainColor from "./svg/facebook-plain-color.svg";
import instagramPlainColor from "./svg/instagram-plain-color.svg";
import linkedinPlainColor from "./svg/linkedin-plain-color.svg";
import twitterPlainColor from "./svg/twitter-plain-color.svg";
import whatsappPlainColor from "./svg/whatsapp-plain-color.svg";
import youtubePlainColor from "./svg/youtube-plain-color.svg";
import pinterestPlainColor from "./svg/pinterest-plain-color.svg";
import telegramPlainColor from "./svg/telegram-plain-color.svg";

export const PlainColorSocial = {
  facebook: facebookPlainColor,
  twitter: twitterPlainColor,
  instagram: instagramPlainColor,
  linkedin: linkedinPlainColor,
  youtube: youtubePlainColor,
  whatsapp: whatsappPlainColor,
  pinterest: pinterestPlainColor,
  telegram: telegramPlainColor
};
