/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";

import "./svg-handler.m.css";
import get from "lodash/get";

const SvgHandlerBase = ({
  svgWrapperClazzName = "",
  clazzName = "",
  xlinkHref,
  invertColor,
  height = "50px",
  width = "50px",
  viewBox,
  mountAt,
}) => {
  let inlineStyles;
  let { url, viewBox: autoViewBox } = xlinkHref || {};
  let path = "";

  if (!viewBox) {
    viewBox = autoViewBox === "undefined" ? "0 0 24 24" : autoViewBox;
  }

  if (invertColor) {
    inlineStyles = { filter: "invert(100%)" };
  }
  if (typeof xlinkHref !== "object" && xlinkHref !== null) {
    path = `${mountAt}${xlinkHref}`;
  } else {
    try {
      url = new URL(url);
      const { pathname = "", hash = "" } = url;
      path = `${mountAt}${pathname}${hash}`;
    } catch (e) {
      path = `${mountAt}${url}`;
    }
  }
  const updatedStyle = clazzName ? `icon ${clazzName}` : "icon";
  if (svgWrapperClazzName) {
    return (
      <div styleName={svgWrapperClazzName}>
        <svg height={height} width={width} className={updatedStyle} viewBox={viewBox} style={inlineStyles}>
          <use xlinkHref={path} />
        </svg>
      </div>
    );
  }

  return (
    <svg height={height} width={width} className={updatedStyle} viewBox={viewBox} style={inlineStyles}>
      <use xlinkHref={path} />
    </svg>
  );
};

SvgHandlerBase.propTypes = {
  svgWrapperClazzName: PropTypes.string,
  clazzName: PropTypes.string,
  viewBox: PropTypes.string,
  xlinkHref: PropTypes.node,
  width: PropTypes.string,
  height: PropTypes.string,
  invertColor: PropTypes.string,
  mountAt: PropTypes.string,
};

const mapStateToProps = (state) => ({
  mountAt: get(state, ["qt", "config", "mountAt"], ""),
});

export const SvgHandler = connect(mapStateToProps)(SvgHandlerBase);
