/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";

import { PrimaryMenu } from "../../primary-menu";
import { NewSearchBar } from "../../new-search";
import { SvgHandler } from "../../../svg-handler/svg-handler";
import searchIcon from "../../../../../assets/icons/search.svg";

import "./menu-with-search-layer.m.css";
import { scrollWindow } from "../../../../utils";

export const NewSearch = () => {
  const dispatch = useDispatch();

  const isSearchFormVisible = useSelector((state) => get(state, ["header", "isSearchFormVisible"], false));

  const toggleSearchForm = () => dispatch({ type: "TOGGLE_SEARCHFORM", isSearchFormVisible: !isSearchFormVisible });
  const closeSearchForm = () => dispatch({ type: "CLOSE_SEARCHFORM" });

  useEffect(() => {
    scrollWindow(isSearchFormVisible);
  }, [isSearchFormVisible]);

  return (
    <>
      <span styleName="loader" onClick={toggleSearchForm}>
        <SvgHandler width="24px" height="24px" xlinkHref={searchIcon} />
      </span>

      {isSearchFormVisible && (
        <>
          <div styleName="overlay-hidden" onClick={closeSearchForm} />
          <div styleName="overlay" onClick={closeSearchForm} />
          <div className="container" styleName="search-wrapper">
            <NewSearchBar />
          </div>
        </>
      )}
    </>
  );
};

export const MenuWithSearchLayer = () => {
  return (
    <div styleName="menu-with-search" className="menu-with-search">
      <div styleName="new-primary-menu" id="new-primary-menu">
        <PrimaryMenu />
      </div>
      <div id="mega-menu" styleName="mega-menu" className="mega-menu" />
      <div id="new-search" styleName="search" className="new-search" />
      <div id="dark-mode-toggle"></div>
    </div>
  );
};
