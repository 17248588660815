/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import get from "lodash/get";

// Hide placeholder if ad has rendered
const onSlotRender = eventData => {
  const placeholderElement = get(eventData, [
    "adElementRef",
    "current",
    "parentElement",
    "parentElement",
    "firstChild"
  ]);
  const placeholderElementClass = get(placeholderElement, ["className"], "");
  if (placeholderElementClass.includes("adBackground")) {
    placeholderElement.style.visibility = "hidden";
  }
};

const viewPortSizeMappingHorizontal728to320 = [
  { viewport: [980, 90], sizes: [[728, 90]] },
  { viewport: [320, 0], sizes: [[320, 50]] },
  { viewport: [0, 0], sizes: [[320, 50]] }
];

const viewPortSizeMappingVertical600to250 = [
  { viewport: [980, 90], sizes: [[300, 600]] },
  { viewport: [320, 0], sizes: [[300, 250]] },
  { viewport: [0, 0], sizes: [[300, 250]] }
];

const viewPortSizeMappingHorizontal970to320 = [
  { viewport: [980, 90], sizes: [[970, 90]] },
  { viewport: [320, 0], sizes: [[320, 50]] },
  { viewport: [0, 0], sizes: [[320, 50]] }
];

const viewPortSizeMappingHorizontalBillboard = [
  { viewport: [980, 90], sizes: [[970, 250]] },
  { viewport: [320, 0], sizes: [[320, 150]] },
  { viewport: [0, 0], sizes: [[320, 150]] }
];

// If you plan to add an entry here, make sure you also add an entry to ad-wrapper.module.css with the same dimensions
export const dfpAdConfig = {
  Leaderboard: {
    adUnit: "Leaderboard",
    sizes: [[728, 90], [320, 50]],
    viewPortSizeMapping: viewPortSizeMappingHorizontal728to320,
    onSlotRender
  },
  SuperLeaderboard: {
    adUnit: "SuperLeaderboard",
    sizes: [[970, 90], [320, 50]],
    viewPortSizeMapping: viewPortSizeMappingHorizontal970to320,
    onSlotRender
  },
  Billboard: {
    adUnit: "Billboard",
    sizes: [[970, 250], [320, 150]],
    viewPortSizeMapping: viewPortSizeMappingHorizontalBillboard,
    onSlotRender
  },
  "Horizontal-Ad": {
    adUnit: "Horizontal_Responsive",
    sizes: [[728, 90], [320, 50]],
    viewPortSizeMapping: viewPortSizeMappingHorizontal728to320,
    onSlotRender
  },
  Mrec: { adUnit: "Mrec", sizes: [[300, 250]], onSlotRender },
  "Vertical-Ad": {
    adUnit: "Vertical_Responsive",
    sizes: [[300, 600], [300, 250]],
    viewPortSizeMapping: viewPortSizeMappingVertical600to250,
    onSlotRender
  },
  "Story-Mrec": { adUnit: "Story_Mrec", sizes: [[300, 250]], onSlotRender },
  "Story-Lrec": { adUnit: "Story_Lrec", sizes: [[336, 280]], onSlotRender },
  "Story-Middle-Ad": {
    adUnit: "Story_Middle_Responsive",
    sizes: [[728, 90], [320, 50]],
    viewPortSizeMapping: viewPortSizeMappingHorizontal728to320,
    onSlotRender
  },
  "Story-Bottom-Ad": {
    adUnit: "Story_Bottom_Responsive",
    sizes: [[728, 90], [320, 50]],
    viewPortSizeMapping: viewPortSizeMappingHorizontal728to320,
    onSlotRender
  }
};
