/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { FacebookProvider, Comments } from "react-facebook";
import get from "lodash/get";
import { connect } from "react-redux";

class FacebookComments extends React.Component {
  facebookCommentProvider = () => {
    const pbConfig = get(this.props, ["config", "pagebuilder-config"], {});
    const { enableComments = false, commentType = "" } = get(pbConfig, ["general", "commentPlugin"], {});

    if (enableComments && commentType === "facebook") {
      const fbAppId = get(this.props, ["config", "public-integrations", "facebook", "app-id"], "");
      const storySlug = get(this.props, ["story", "slug"], "");
      return (
        <FacebookProvider appId={fbAppId} wait>
          <Comments href={global.location && `${global.location.origin}/${storySlug}`} />
        </FacebookProvider>
      );
    }
    return null;
  };

  render() {
    return this.facebookCommentProvider();
  }
}

const mapStatetoProps = (state) => {
  return {
    config: get(state, ["qt", "config"], {}),
  };
};

export const FacebookComment = connect(mapStatetoProps)(FacebookComments);
