/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from 'react';
import { CustomLink } from '../custom-link';

export function NewsHeading({ story = {}, config = {}, index }) {
  if (story.metadata['linked-story-slug']) {
    const subdirectoryPath = getSubDirectoryPath(config) || '/';
    const href = `${subdirectoryPath}${story.metadata['linked-story-slug']}`;
    return (
      <CustomLink id={index} key={story.id} href={href}>
        <article dangerouslySetInnerHTML={{ __html: story.headline }} />
      </CustomLink>
    );
  } else {
    return (
      <article id={index} key={story.id}>
        {story.headline}
      </article>
    );
  }
}

function getSubDirectoryPath(config) {
  try {
    const sketchesUrl = new URL(config['sketches-host']);
    const pathName = sketchesUrl.pathname;
    return pathName === '/' ? pathName : `${pathName}/`;
  } catch (e) {
    console.log('Error getting subdirectory pathname', e);
  }
}
