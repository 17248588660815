/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import get from "lodash/get";
// Utility functions for inserting ads in story based on Pagebuilder config

export const addSlotsToStory = (storyCards, slots, customSlotAfterStory) => {
  for (const slotKey of Object.keys(slots)) {
    const slotConfig = slots[slotKey];
    // inject ad or widget after the story
    if (slotKey === "story") {
      if (slotConfig.after) {
        customSlotAfterStory = {
          ...customSlotAfterStory,
          ...slotConfig.after[0]
        };
      }
    } else {
      let numberOfElements = get(storyCards, [slotKey - 1, "story-elements", "length"], 0);
      if (numberOfElements === 0) continue;
      if (slotConfig.before) {
        storyCards[slotKey - 1]["story-elements"].splice(0, 0, ...slotConfig.before);
      }
      // numberOfElements might change if a new slot is added
      numberOfElements = get(storyCards, [slotKey - 1, "story-elements", "length"], 0);
      if (slotConfig.after) {
        storyCards[slotKey - 1]["story-elements"].splice(numberOfElements, 0, ...slotConfig.after);
      }
    }
  }
  return { storyCards, customSlotAfterStory };
};
