/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import get from "lodash/get";
import { breakpoint } from "../../../utils/breakpoint";

// A copied version of SearchBox from https://github.com/quintype/quintype-node-components/blob/master/src/components/search-box.js
// only change is the "this.props.navigateTo(`/search" to  "this.props.navigateTo(`/${mountAt}search"
// once qt/component supports subdirectory

class SearchBoxBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      query: this.props.initValue || "",
    };
  }

  componentDidMount() {
    if (!breakpoint("mobile")) {
      this.focus();
    }
  }

  onSubmit(e) {
    e.preventDefault();
    this.state.query && this.props.navigateTo(`${this.props.mountAt}/search?q=${encodeURIComponent(this.state.query)}`);
    this.props.onSubmitHandler && this.props.onSubmitHandler(this.state.query);
  }

  keyPress(e) {
    if (e.keyCode === 27) this.props.onEscape && this.props.onEscape();
  }

  focus() {
    this.textInput && this.textInput.focus();
  }

  clearText() {
    this.setState({ query: "" });
    this.props.onChange && this.props.onChange("");
    this.focus();
  }

  static defaultTemplate({ children }) {
    return children;
  }

  render() {
    const Render = this.props.template || this.defaultTemplate;
    return (
      <form
        role="search"
        action={`${this.props.mountAt}/search`}
        onSubmit={(e) => this.onSubmit(e)}
        className={this.props.className}
        ref={this.props.formRef}
      >
        <Render>
          <input
            type="search"
            name="q"
            placeholder={this.props.placeholder}
            value={this.state.query}
            onChange={(e) => {
              this.setState({ query: e.target.value });
              this.props.onChange && this.props.onChange(e.target.value);
            }}
            className={this.props.inputClassName}
            id={this.props.inputId}
            ref={(input) => (this.textInput = input)}
            onKeyDown={(e) => this.keyPress(e)}
            data-testid="search-input"
          />
        </Render>
      </form>
    );
  }
}

SearchBoxBase.propTypes = {
  initValue: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  formRef: PropTypes.any,
  inputClassName: PropTypes.string,
  inputId: PropTypes.string,
  template: PropTypes.elementType,
  onSubmitHandler: PropTypes.func,
  onEscape: PropTypes.func,
  mountAt: PropTypes.string,
  navigateTo: PropTypes.func,
  onChange: PropTypes.func,
};

const mapStateToProps = (state) => ({
  mountAt: get(state, ["qt", "config", "mountAt"], ""),
});

const mapDispatchToProps = (dispatch) => ({
  navigateTo: function (url) {
    global.app.navigateToPage(dispatch, url);
  },
});

export const SearchBox = connect(mapStateToProps, mapDispatchToProps, null, { forwardRef: true })(SearchBoxBase);
