/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import { WithLazy } from "@quintype/components";
import PropTypes from "prop-types";
import React, { useState } from "react";

/*
 * This component is used in the case where we want WithLazy to trigger
 * a passed function instead of rendering something directly. Use this
 * component only when your focus is on running a function instead of
 * displaying a component directly via IntersectionObserver.
 */
const WithLazyTrigger = props => {
  const [onIntersectionCalled, setOnIntersectionCalled] = useState(false);
  return (
    <WithLazy margin="0px">
      {() => {
        // To prevent any potential re-rendering
        if (!onIntersectionCalled) {
          props.onIntersection();
          setOnIntersectionCalled(true);
        }
        return <></>;
      }}
    </WithLazy>
  );
};

WithLazyTrigger.propTypes = {
  onIntersection: PropTypes.func
};

export default WithLazyTrigger;
