/*
 *  ************************************************************************
 *  *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import { useExternalScript } from "../../utils/custom-hooks";

const googleClientLibraryUri = "https://accounts.google.com/gsi/client";

const GoogleOneTapBase = ({ googleClientId, updateMember, member, memberLoaded, enableSSO, sketchesHost }) => {
  if (!enableSSO || !googleClientId || !memberLoaded || (memberLoaded && member)) return null;

  const scriptLoadState = useExternalScript(googleClientLibraryUri);
  if (scriptLoadState !== "ready") return null;

  window.google.accounts.id.initialize({
    client_id: googleClientId,
    // auto_select: true,
    // context: "signin",
    state_cookie_domain: sketchesHost,
    // itp_support: true, // enable upgraded onetap UX
    callback: (onetapResp) => handleCallback(onetapResp),
  });
  window.google.accounts.id.prompt();

  const handleCallback = async (onetapResp) => {
    try {
      const brkeResp = await fetch("/api/auth/v1/login/google-one-tap", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ token: onetapResp.credential }),
      });
      const brkeRespJson = await brkeResp.json();
      await updateMember(brkeRespJson.user);
    } catch (e) {
      console.log(e);
    }
  };

  return <></>;
};

const mapStateToProps = (state) => {
  return {
    googleClientId: get(state, ["qt", "config", "public-integrations", "google", "app-id"]),
    member: get(state, ["member"], null),
    memberLoaded: get(state, ["memberLoaded"], false),
    enableSSO: get(state, ["qt", "config", "pagebuilder-config", "header", "enableSSO"], false),
    sketchesHost: get(state, ["qt", "config", "sketches-host"]),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateMember: (member) => dispatch({ type: "MEMBER_UPDATED", member }),
});

export const GoogleOneTap = connect(mapStateToProps, mapDispatchToProps)(GoogleOneTapBase);

GoogleOneTapBase.propTypes = {
  googleClientId: PropTypes.string,
  updateMember: PropTypes.func,
  member: PropTypes.object,
  memberLoaded: PropTypes.bool,
  enableSSO: PropTypes.bool,
  sketchesHost: PropTypes.string,
};
