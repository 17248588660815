/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import { get } from "lodash";
import { PropTypes } from "prop-types";
import React from "react";
import { useSelector } from "react-redux";
import { CommentingWidget } from "../commenting-widget";
import "./story-footer.module.css";

export const StoryFooter = ({ story, isInfiniteScrollEnabled, hydrate  }) => {
  const disableServiceWorkerCache = useSelector(state => get(state, ["qt","config", "pagebuilder-config", "general", "serviceWorker", "disableCache"], false));
  if (!disableServiceWorkerCache && !isInfiniteScrollEnabled && !global.qtLoadedFromShell && !hydrate) {
    return <div id={`story-footer-${get(story, ["id"], "")}`} />;
  }
  return (
    <div styleName="metype">
      <CommentingWidget story={story} />
    </div>
  );
};


StoryFooter.propTypes = {
  story: PropTypes.object,
  isInfiniteScrollEnabled: PropTypes.boolean,
  hydrate: PropTypes.boolean
};
