/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "@quintype/components";
import PropTypes from "prop-types";
import get from "lodash/get";

export const CustomLinkBase = (props) => {
  const { itemType, href = "", ...restProps } = props;
  const sketchesHost = useSelector((state) => get(state, ["qt", "config", "sketches-host"], ""));
  const _href = sketchesHost ? new URL(href, sketchesHost) : href;
  const customCallback = () => {
    props.callback && props.callback();
    props.closeAll();
  };
  const disableAjaxLinks = itemType === "link";
  return <Link href={_href} {...restProps} callback={customCallback} disableAjaxLinks={disableAjaxLinks} />;
};

const mapDispatchToProps = (dispatch) => ({
  closeAll: () => dispatch({ type: "CLOSE_ALL" }),
});

export const CustomLink = connect(() => ({}), mapDispatchToProps)(CustomLinkBase);

CustomLinkBase.propTypes = {
  closeAll: PropTypes.func,
  callback: PropTypes.func,
  itemType: PropTypes.string,
};
