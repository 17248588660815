/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
const shouldDeferAdsBy = (deferAds, deferAdsBy) => {
  /* deferAdsBy is a number in ms from publisher.yml config
   * deferAds is from pagebuilder
   */
  if (deferAds && typeof deferAdsBy === "number") {
    return deferAdsBy;
  }
  if (deferAds) return 2500;
  return 0;
};

export { shouldDeferAdsBy };
