/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import { AdService } from "../../ad-service";
// eslint-disable-next-line react/display-name

import "./ad-slot-row.m.css";

const AdSlotRow = (props) => {
  const metadata = get(props, ["metadata"], {});
  const adLayout = get(metadata, ["layout"], "Leaderboard");
  const rowId = get(metadata, ["rowId"], "");
  const storyId = get(metadata, ["storyId"], "");
  const isStoryInfiniteScrollEnabled = get(metadata, ["isStoryInfiniteScrollEnabled"], false);
  const slotId = isStoryInfiniteScrollEnabled ? `PB-${rowId}-${storyId}` : `PB-${rowId}`;

  const { targetingId: adTargetingId, customCode } = get(metadata, ["config"], {});

  return (
    <div className="component-wrapper" styleName="ad-Wrapper" data-test-id="ad">
      <AdService
        row
        adtype={adLayout}
        customCode={customCode}
        adTargetingId={adTargetingId}
        layoutName="PageBuilder"
        slotId={slotId}
      />
    </div>
  );
};
AdSlotRow.propTypes = {
  metadata: PropTypes.object
};

export { AdSlotRow };
