/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { CustomLink } from "./custom-link";
import omit from "@babel/runtime/helpers/objectWithoutProperties";
import PropTypes from "prop-types";
import get from "lodash/get";

function MenuLink(props) {
  const { title, completeUrl, isExternalLink, "item-type": itemType } = get(props, ["item"], {});
  const currentProps = {
    itemType: itemType,
    ...(isExternalLink && { externalLink: completeUrl, target: "_blank", rel: "noopener noreferrer" }),
    ...omit(props, ["item"]),
    href: completeUrl,
  };

  return React.createElement(CustomLink, currentProps, title);
}

MenuLink.propTypes = {
  item: PropTypes.object,
};

export { MenuLink };
