/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import PropTypes from "prop-types";

import "./ad-wrapper.module.css";

const AdWrapper = ({ adLayout = "Leaderboard", children }) => {
  return (
    <div styleName="adContainer" data-test-id="ad">
      <div styleName={`adPlaceholder ${adLayout}`}>
        <div styleName="adBackground" />
        {children}
      </div>
    </div>
  );
};

AdWrapper.propTypes = {
  adLayout: PropTypes.string,
  children: PropTypes.element
};

export { AdWrapper };
