/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { useState } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import { PropTypes } from "prop-types";

import { getMenuData, getMenuItem } from "../header-helper-components";

import "./primary-menu.m.css";

export const PrimaryMenu = (props) => {
  const [currentMenuId, setCurrentMenuId] = useState(null);
  const [customStyleName, setCustomStyleName] = useState("");
  const [customPrimaryStyleName, setCustomPrimaryStyleName] = useState("");

  const showChild = (id) => {
    if (id === currentMenuId) setCurrentMenuId("");
    else setCurrentMenuId(id);
  };

  const qtConfig = useSelector((state) => get(state, ["qt"], {}));

  const menu = get(qtConfig, ["data", "navigationMenu"], []);
  const domainSlug = get(qtConfig, ["config", "domainSlug"], "");
  const getMenuGroup = domainSlug ? "header-menu" : "default";

  const primaryMenuItems = getMenuData(menu, getMenuGroup, domainSlug);

  const onClick = (id) => {
    showChild(id);
    setCustomStyleName("mobile-sub-menu-wrapper");
    setCustomPrimaryStyleName("mobile-primary-item");
  };

  const subMenuItems = (menuItem, isSidebarMenu = false) => {
    return (
      <>
        <div styleName={`sub-menu-wrapper ${customStyleName}`} data-testid="primary-sub-items" className="sub-menu-wrapper">
          <ul styleName="menu-list">
            {isSidebarMenu && <li styleName="sub-items"> {getMenuItem(menuItem)}</li>}
            {menuItem.children.map((childItem, index) => (
              <li key={`child-item-${index}`} styleName="sub-items" data-testid="primary-sub-item">
                {getMenuItem(childItem)}
              </li>
            ))}
          </ul>
        </div>
      </>
    );
  };

  const primaryMenuHandler = (menuItem, index, children) => {
    if (props.isMobileMenu) {
      return (
        <li
          key={`primary-menu-item-${index}`}
          data-testid="primary-item"
          className={`primary-item`}
          styleName={`primary-item ${currentMenuId === menuItem.id ? customPrimaryStyleName : ""}`}
          onClick={() => onClick(menuItem.id)}
        >
          {children()}
        </li>
      );
    }
    return (
      <li
        key={`primary-menu-item-${index}`}
        styleName="primary-item"
        data-testid="primary-item"
        onMouseEnter={() => showChild(menuItem.id)}
        onMouseLeave={() => showChild(menuItem.id)}
      >
        {children()}
      </li>
    );
  };

  return (
    <ul styleName="menu-wrapper" data-testid="primary-menu">
      {primaryMenuItems.map((menuItem, index) => {
        const isSidebarMenu =
          props.isMobileMenu &&
          menuItem["item-type"] !== "placeholder" &&
          (menuItem.url || menuItem.completeUrl) &&
          menuItem.children.length > 0;

        const children = () => (
          <>
            {getMenuItem(menuItem, isSidebarMenu)}
            {menuItem.children.length > 0 && <div styleName="chevron" />}
            {currentMenuId === menuItem.id ? subMenuItems(menuItem, isSidebarMenu) : null}
          </>
        );

        return primaryMenuHandler(menuItem, index, children);
      })}
    </ul>
  );
};

PrimaryMenu.propTypes = {
  isMobileMenu: PropTypes.bool,
};
