/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { connect } from "react-redux";
import { ErrorBoundary } from "./error-boundary";
import canUseDOM from "../../utils/can-use-dom";
import { PropTypes } from "prop-types";
import get from "lodash/get";

const getHtml = (widgetTemplate = "") => ({ __html: widgetTemplate });

const getConcatinatedWidgets = (widgets = []) =>
  widgets.reduce((accumulator, currentElement) => {
    const template = currentElement.template || "";
    const decoded = canUseDOM ? atob(template) : Buffer.from(template, "base64").toString("binary");
    return accumulator + decoded;
  }, "");

const InjectWidgetBase = (props) => {
  const { widgetCode } = props;
  let decodedValue = "";
  if (widgetCode && widgetCode.constructor === Array) {
    decodedValue = getConcatinatedWidgets(widgetCode);
  } else {
    decodedValue = canUseDOM ? atob(widgetCode || "") : Buffer.from(widgetCode || "", "base64").toString("binary");
  }
  if (!decodedValue) return null;
  return (
    <ErrorBoundary>
      <div dangerouslySetInnerHTML={getHtml(decodedValue)} />
    </ErrorBoundary>
  );
};

InjectWidgetBase.propTypes = {
  widgetCode: PropTypes.string,
};

function mapStateToProps(state) {
  return {
    widgetCode: get(state.qt, ["config", "pagebuilder-config", "general", "thirdPartyIntegrations", "widgetCode"], ""),
  };
}

export const InjectWidget = connect(mapStateToProps, () => ({}))(InjectWidgetBase);
