/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { Suspense, lazy } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import loadingIcon from "../../../assets/icons/loading.svg";
import { SvgHandler } from "../svg-handler/svg-handler";
import hamburgerIcon from "../../../assets/icons/hamburger.svg";
import closeIcon from "../../../assets/icons/social/close.svg";
import { CSSTransition } from "react-transition-group";
import { getMenuData } from "./header-helper-components";
import { isEmpty } from "../../utils";
import get from "lodash/get";

class HeaderSidebarBase extends React.Component {
  constructor(props) {
    super(props);
    this.SidebarTemplate = null;
    this.state = {
      loadSidebar: false,
      loadingIndicator: false,
    };
  }

  componentDidUpdate(prevProp) {
    const wasSidebarVisible = get(prevProp, ["header", "isSidebarVisible"]);
    const { isSidebarVisible } = this.props.header;
    if (isSidebarVisible !== wasSidebarVisible && isSidebarVisible) {
      this.dynamicImport();
    }
  }

  async dynamicImport() {
    if (isEmpty(this.SidebarTemplate)) {
      this.setState({ loadSidebar: false });
      this.SidebarTemplate = await lazy(() => import(/* webpackChunkName: "SidebarTemplate" */ "./sidebar/index"));
    }
    this.setState({ loadingIndicator: true });
  }

  displayStatus = (loadingIndicator, isSidebarVisible, loadSidebar) => {
    if (!isSidebarVisible) {
      return <SvgHandler xlinkHref={hamburgerIcon} width="24" height="24" svgWrapperClazzName="svg-wrapper" />;
    } else if (isSidebarVisible && loadingIndicator) {
      this.setState({ loadSidebar: true, loadingIndicator: false });
      return <SvgHandler xlinkHref={loadingIcon} width="24" height="24" svgWrapperClazzName="svg-wrapper" />;
    } else if (isSidebarVisible && loadSidebar) {
      return (
        <>
          <SvgHandler
            xlinkHref={closeIcon}
            clazzName="closeIcon"
            width="24"
            height="24"
            svgWrapperClazzName="svg-wrapper desktop"
          />
          <SvgHandler xlinkHref={hamburgerIcon} width="24" height="24" svgWrapperClazzName="svg-wrapper mobile" />
        </>
      );
    }
  };

  render() {
    const {
      publisherName,
      socialLinks,
      languageDirection,
      header = {},
      closeAll,
      menuItems,
      toggleSidebar,
      isSingleLayer,
      pageBuilderConfig,
      socialFollowStyle,
      appStoreDownloadLink,
      googlePlayDownloadLink,
      localizedElementsData = {},
      sketchesHost,
    } = this.props;
    const { isSidebarVisible } = header;
    const { loadSidebar, loadingIndicator } = this.state;
    const { buttonLabels = {} } = localizedElementsData;
    const { subscribe: localizedSubscribe } = buttonLabels;
    return (
      <div onClick={toggleSidebar}>
        <div>{this.displayStatus(loadingIndicator, isSidebarVisible, loadSidebar)}</div>
        <CSSTransition in={isSidebarVisible} timeout={{ enter: 300, exit: 500 }} classNames="side-bar-transition">
          <div>
            {loadSidebar ? (
              <Suspense fallback={null}>
                <this.SidebarTemplate
                  customCssClasses=""
                  isSidebarVisible={isSidebarVisible}
                  closeSidebar={toggleSidebar}
                  closeAll={closeAll}
                  menuItems={menuItems}
                  publisherName={publisherName}
                  languageDirection={languageDirection}
                  socialLinks={socialLinks}
                  headerClass={isSingleLayer ? "single-layer" : ""}
                  pageBuilderConfig={pageBuilderConfig}
                  socialStyle={socialFollowStyle}
                  appStoreDownloadLink={appStoreDownloadLink}
                  googlePlayDownloadLink={googlePlayDownloadLink}
                  localizedSubscribe={localizedSubscribe}
                  sketchesHost={sketchesHost}
                />
              </Suspense>
            ) : null}
          </div>
        </CSSTransition>
      </div>
    );
  }
}
HeaderSidebarBase.propTypes = {
  header: PropTypes.shape({
    isSidebarVisible: PropTypes.bool,
  }),
  toggleSidebar: PropTypes.func,
  menuItems: PropTypes.array,
  closeAll: PropTypes.func,
  publisherName: PropTypes.string,
  socialLinks: PropTypes.object,
  languageDirection: PropTypes.string,
  isSingleLayer: PropTypes.bool,
  pageBuilderConfig: PropTypes.object,
  socialFollowStyle: PropTypes.string,
  enableAppDownloadLinks: PropTypes.bool,
  appStoreDownloadLink: PropTypes.string,
  googlePlayDownloadLink: PropTypes.string,
  localizedElementsData: PropTypes.shape({
    buttonLabels: PropTypes.shape({
      subscribe: PropTypes.string,
    }),
  }),
  sketchesHost: PropTypes.string,
};

const mapStateToProps = (state) => {
  const menu = get(state.qt, ["data", "navigationMenu"], []);
  const { config } = get(state, ["qt"], {});
  const domainSlug = get(config, ["domainSlug"], "");
  const menuItems = getMenuData(menu, "sidebar-menu", domainSlug);
  const { enableLocalization = false, localizedElements = {} } = get(
    state.qt,
    ["config", "pagebuilder-config", "general", "localization"],
    {}
  );
  return {
    header: state.header || {},
    publisherName: get(config, ["publisher-name"], "Madrid"),
    socialLinks: get(config, ["social-links"], {}),
    isSingleLayer: get(config, ["pagebuilder-config", "header", "headerStyle"], "header_1").includes("single"),
    pageBuilderConfig: get(config, ["pagebuilder-config"], {}),
    socialFollowStyle: get(config, ["pagebuilder-config", "header", "socialFollowStyle"], "circular-color-svg"),
    enableAppDownloadLinks: get(config, ["pagebuilder-config", "header", "enableAppDownloadLinksButton"], false),
    appStoreDownloadLink: get(
      config,
      ["pagebuilder-config", "general", "appDownloadLinks", "appStoreDownloadLink"],
      ""
    ),
    googlePlayDownloadLink: get(
      config,
      ["pagebuilder-config", "general", "appDownloadLinks", "googlePlayDownloadLink"],
      ""
    ),
    menuItems,
    localizedElementsData: enableLocalization ? localizedElements : {},
    languageDirection: get(config, ["language", "direction"], "ltr"),
    sketchesHost: get(config, ["sketches-host"], ""),
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleSidebar: () => dispatch({ type: "TOGGLE_SIDEBAR_MENU" }),
  closeAll: () => dispatch({ type: "CLOSE_ALL" }),
});

export const HeaderSidebar = connect(mapStateToProps, mapDispatchToProps)(HeaderSidebarBase);

export { HeaderSidebarBase };
