/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import PropTypes from "prop-types";
import React from "react";
import { nanoid } from "nanoid";
import { AdService } from "../ad-service";
import { WidgetComponent } from "../Widget";
import { AdWrapper } from "../ad-wrapper";
import { dfpAdConfig } from "../../dfp-ad-config";

export const detectComponent = (type, isInfiniteScrollEnabled, disableServiceWorkerCache = false) => {
  // If infinite scroll is not enabled and is not loaded from shell

  if (!disableServiceWorkerCache && !isInfiniteScrollEnabled && !global.qtLoadedFromShell) {
    return EmptySlotComponent;
  }

  switch (type) {
    case "ad":
      return AdComponent;
    case "widget":
      return WidgetComp;
  }
};

export const EmptySlotComponent = ({ id, layout }) => {
  /* A component that returns an empty div with props
   * So that it can be hydrated later based on the id
   */

  return <div id={id}> {dfpAdConfig[layout] && <AdWrapper adLayout={layout} />} </div>;
};

export const AdComponent = (component) => {
  const { layout, layoutLabel, config } = component;
  const { targetingId } = config;

  return (
    <div className="app-ad app-ad--story-horizontal" data-test-id="ad">
      <AdService adtype={layout} layoutName={layoutLabel} adTargetingId={targetingId} shouldRefreshOnce />
    </div>
  );
};

export const WidgetComp = ({ widgetCode, id }) => {
    let widget;
    try {
      if (window) {
        widget = window.atob(widgetCode);
      } else {
        const buf = Buffer.from(widgetCode, "base64");
        widget = buf.toString("utf-8");
      }
    } catch (e) {
      widget = "";
    }

    const newId = `${id}-${nanoid()}`;
    const decodeWidget = decodeURIComponent(widget);
    const updatedWidgetCode = decodeWidget.replace(/\[unique-id]/g, newId);

    return (
      <div className="app-ad app-ad--story-horizontal" id={id} data-test-id="widget">
        <WidgetComponent widgetValue={updatedWidgetCode} />
      </div>
    );
};

WidgetComp.propTypes = {
  widgetCode: PropTypes.string,
};

EmptySlotComponent.propTypes = {
  id: PropTypes.string,
  layout: PropTypes.string,
};
