/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { useSelector } from "react-redux";
import { PropTypes } from "prop-types";
import get from "lodash/get";

import { WithLazy } from "@quintype/components";

import { replaceScriptNodes } from "../../utils";
import { ErrorBoundary } from "../error-boundary";
import "./widget.module.css";

export class CustomWidget extends React.Component {
  constructor(props) {
    super(props);
    this.widgetSlot = React.createRef();
  }

  componentDidMount = () => {
    const currentNode = this.widgetSlot.current;
    currentNode && replaceScriptNodes(currentNode);
  };

  render() {
    if (!this.props.widgetValue || typeof window === "undefined") return null;
    return (
      <ErrorBoundary>
        <div
          styleName="widgetWrapper"
          ref={this.widgetSlot}
          dangerouslySetInnerHTML={{ __html: this.props.widgetValue }}
          data-test-id="widget"
        />
      </ErrorBoundary>
    );
  }
}

CustomWidget.propTypes = {
  widgetValue: PropTypes.string,
};

export const WidgetComponent = (props) => {
  const { lazyLoadWidget = true } = useSelector((state) =>
    get(state, ["qt", "config", "pagebuilder-config", "general", "widget"], {})
  );
  if (lazyLoadWidget) {
    return <WithLazy margin="0px">{() => <CustomWidget {...props} />}</WithLazy>;
  }
  return <CustomWidget {...props} />;
};

WidgetComponent.propTypes = {
  widgetValue: PropTypes.string,
};
