/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import get from "lodash/get";
import { PropTypes } from "prop-types";
import React from "react";
import { connect } from "react-redux";
import { CommentWidget } from "../../comment-widget";
import "./commenting-widget.module.css";

export class CommentingWidgetComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showComments: false,
    };
    this.toggleComments = this.toggleComments.bind(this);
  }

  toggleComments(event) {
    event.preventDefault();
    event.stopPropagation();
    this.setState({ showComments: !this.state.showComments });
  }

  commentsButton(text) {
    return (
      <div styleName="show-comments">
        <button onClick={this.toggleComments} styleName="show-comments-btn">
          {text}
        </button>
      </div>
    );
  }

  commentsWithButton() {
    const { localization = {} } = get(this.props.pbConfig, ["general"], {});
    const { enableLocalization = false, localizedElements = {} } = localization;
    const { buttonLabels = {} } = enableLocalization ? localizedElements : {};
    const { metypeShowComments = "", metypeHideComments = "" } = buttonLabels;
    return (
      <>
        {!this.state.showComments && this.commentsButton(metypeShowComments || "Show Comments")}
        {this.state.showComments && <CommentWidget story={this.props.story} lazy={false} />}
        {this.state.showComments && this.commentsButton(metypeHideComments || "Hide Comments")}
      </>
    );
  }

  render() {
    if (!this.props.enableComments) return null;
    return this.props.hideComments ? this.commentsWithButton() : <CommentWidget story={this.props.story} />;
  }
}

function mapStateToProps(state) {
  const { enableComments = false, hideCommentsButton = true } = get(
    state,
    ["qt", "config", "pagebuilder-config", "general", "commentPlugin"],
    {}
  );
  return {
    enableComments,
    hideComments: hideCommentsButton,
    pbConfig: get(state, ["qt", "config", "pagebuilder-config"], {}),
  };
}

CommentingWidgetComponent.propTypes = {
  hideComments: PropTypes.bool,
  story: PropTypes.object,
  pbConfig: PropTypes.object,
  enableComments: PropTypes.bool,
};

export const CommentingWidget = connect(mapStateToProps)(CommentingWidgetComponent);
