/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { lazy, Suspense, useEffect } from "react";
import get from "lodash/get";
import { useDispatch, useSelector } from "react-redux";

import { scrollWindow } from "../../../utils";
import hamburgerIcon from "../../../../assets/icons/hamburger.svg";
import { SvgHandler } from "../../svg-handler/svg-handler";

import "./new-hamburger-menu.m.css";

export const NewHamburgerMenu = () => {
  const dispatch = useDispatch();
  const closeSideBarMenu = () => dispatch({ type: "CLOSE_ALL" });

  const SideBar = lazy(() => import("./sidebarmenu"));

  const isSidebarVisible = useSelector((state) => get(state, ["header", "isSidebarVisible"], false));

  const updateSidebarValue = () => {
    if (isSidebarVisible) {
      closeSideBarMenu();
    } else {
      dispatch({ type: "OPEN_SIDEBAR_MENU" });
    }
  };

  useEffect(() => {
    scrollWindow(isSidebarVisible);
  }, [isSidebarVisible]);

  return (
    <Suspense fallback={<div>...</div>}>
      <span styleName="loader" className="loader" onClick={updateSidebarValue}>
        <SvgHandler width="24px" height="24px" xlinkHref={hamburgerIcon} />
      </span>
      {isSidebarVisible && <SideBar closeSideBarMenu={closeSideBarMenu} />}
    </Suspense>
  );
};
