/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { connect } from "react-redux";
import { LazyLoadImages, ResponsiveImage } from "@quintype/components";
import get from "lodash/get";
import PropTypes from "prop-types";
import { SvgHandler } from "../svg-handler/svg-handler";
import fallbackIcon from "../../../assets/icons/fallback-image.svg";

function ImageFallbackBase(props) {
  const { bgColor = "#d8e7f6", className = "", config = {}, story } = props;
  const imageFallbackUrl = get(config, ["pagebuilder-config", "general", "fallbackImage", "url"]);

  const template = get(story, ["story-template"]);
  const videoIcon = template === "video" ? "video" : "";
  if (imageFallbackUrl) {
    return (
      <div className="image-fallback">
        <figure className={`set-fallback ${className} ${videoIcon}`}>
          <LazyLoadImages margin={"0px"}>
            <ResponsiveImage
              slug={imageFallbackUrl}
              metadata={{}}
              aspectRatio={[16, 9]}
              defaultWidth={480}
              widths={[250, 480, 640]}
              imgParams={{ auto: ["format", "compress"] }}
              className=""
              alt={"image-fallback"}
            />
          </LazyLoadImages>
        </figure>
      </div>
    );
  }

  return (
    <div className="image-fallback" style={{ backgroundColor: bgColor || "initial" }}>
      <figure className={className}>
        {template === "video" ? (
          <div className="video" />
        ) : (
          <SvgHandler xlinkHref={fallbackIcon} clazzName="fallback-image" width="80px" height="50px" />
        )}
      </figure>
    </div>
  );
}
ImageFallbackBase.propTypes = {
  bgColor: PropTypes.string,
  iconColor: PropTypes.string,
  className: PropTypes.string,
  config: PropTypes.object,
  story: PropTypes.object,
};

function mapStateToProps(state) {
  return {
    config: state.qt.config || {},
  };
}

export const ImageFallback = connect(mapStateToProps, () => ({}))(ImageFallbackBase);
