/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import { CircularColorSocial } from "./circular-color";
import { CircularPlainSocial } from "./circular-plain";
import { PlainSocial } from "./plain";
import { PlainColorSocial } from "./plain-color";
import { SquareSocial } from "./square";

const getSocialComponentMap = type => {
  switch (type) {
    case "circular-color-svg":
      return CircularColorSocial;
    case "circular-plain-svg":
      return CircularPlainSocial;
    case "plain-color-svg":
      return PlainColorSocial;
    case "plain-svg":
      return PlainSocial;
    case "square-svg":
      return SquareSocial;
    default:
      return CircularColorSocial;
  }
};

export default getSocialComponentMap;
