/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import facebookCircleColor from "./svg/facebook-circle-color.svg";
import instagramCircleColor from "./svg/instagram-circle-color.svg";
import linkedinCircleColor from "./svg/linkedin-circle-color.svg";
import twitterCircleColor from "./svg/twitter-circle-color.svg";
import whatsappCircleColor from "./svg/whatsapp-circle-color.svg";
import youtubeCircleColor from "./svg/youtube-circle-color.svg";
import pinterestCircleColor from "./svg/pinterest-circle-color.svg";
import telegramCircleColor from "./svg/telegram-circle-color.svg";

export const CircularColorSocial = {
  facebook: facebookCircleColor,
  twitter: twitterCircleColor,
  instagram: instagramCircleColor,
  linkedin: linkedinCircleColor,
  youtube: youtubeCircleColor,
  whatsapp: whatsappCircleColor,
  pinterest: pinterestCircleColor,
  telegram: telegramCircleColor,
};

export default CircularColorSocial;
