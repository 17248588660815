/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import get from "lodash/get";

import { getMenuData, getMenuItem } from "../header-helper-components";
import { SvgHandler } from "../../svg-handler/svg-handler";
import closeIcon from "../../../../assets/icons/social/close.svg";
import IconMore from "../../../../assets/icons/icons-more-vertical.svg";

import "./mega-menu.m.css";
import { scrollWindow } from "../../../utils";

export const MegaMenu = () => {
  const qtConfig = useSelector((state) => get(state, ["qt"], {}));
  const alignItem =
    get(qtConfig, ["config", "language", "direction"], "ltr") === "ltr"
      ? { "margin-left": "auto" }
      : { "margin-right": "auto" };

  const isMegaMenuVisible = useSelector((state) => get(state, ["header", "isMegaMenuVisible"], false));
  const menu = get(qtConfig, ["data", "navigationMenu"], []);
  const domainSlug = get(qtConfig, ["config", "domainSlug"], "");
  const megaMenuItems = getMenuData(menu, "mega-menu", domainSlug) || [];

  const dispatch = useDispatch();
  const toggleForm = () => dispatch({ type: "TOGGLE_MEGAMENU", isMegaMenuVisible: !isMegaMenuVisible });
  const closeMegaMenu = () => dispatch({ type: "CLOSE_ALL" });

  useEffect(() => {
    scrollWindow(isMegaMenuVisible);
  }, [isMegaMenuVisible]);

  const showIcon = isMegaMenuVisible ? (
    <SvgHandler width="20px" height="20px" xlinkHref={closeIcon} />
  ) : (
    <SvgHandler width="24px" height="24px" xlinkHref={IconMore} />
  );

  if (megaMenuItems.length < 1) return null;
  const iconStyle = isMegaMenuVisible ? "loader closeIconHeight" : "loader";
  return (
    <>
      <div styleName={iconStyle} onClick={toggleForm}>
        {showIcon}
      </div>
      {isMegaMenuVisible && (
        <>
          <div styleName="overlay-hidden" onClick={closeMegaMenu} />
          <div styleName="overlay" onClick={closeMegaMenu}>
            <div styleName="wrapper" className="container" data-testid="mega-menu">
              <div
                styleName="mega-items"
                style={
                  megaMenuItems.length < 5
                    ? { gridTemplateColumns: `repeat(${megaMenuItems.length}, 156px)`, ...alignItem }
                    : { gridTemplateColumns: "repeat(5, 156px)", margin: "0 auto" }
                }
              >
                {megaMenuItems.map((subItem, index) => {
                  return (
                    <ul key={`item-${index}`} styleName="item-wrapper" data-testid="mega-items">
                      <li styleName="main-item" data-testid="mega-item">
                        {getMenuItem(subItem)}
                      </li>
                      {subItem.children.length > 0 &&
                        subItem.children.map((item, index) => (
                          <li key={`sub-item-${index}`} styleName="sub-items" data-testid="mega-sub-items">
                            {getMenuItem(item)}
                          </li>
                        ))}
                    </ul>
                  );
                })}
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};
