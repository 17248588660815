/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import get from "lodash/get";
import { CustomLink } from "../../custom-link";
import { SvgHandler } from "../../svg-handler/svg-handler";
import PropTypes from "prop-types";
import googlePlayIcon from "../../../../assets/icons/download-links/googleplay.svg";
import appStoreIcon from "../../../../assets/icons/download-links/appstore.svg";
import { connect } from "react-redux";

const MobileDownloadLinksBase = props => {
  return (
    <>
      {props.appStoreDownloadLink && (
        <CustomLink href={props.appStoreDownloadLink} target="_blank">
          <SvgHandler
            xlinkHref={appStoreIcon}
            width="120"
            height="40"
            viewBox="0 0 120 40"
            clazzName="app-store-icon"
          />
        </CustomLink>
      )}
      {props.googlePlayDownloadLink && (
        <CustomLink href={props.googlePlayDownloadLink} target="_blank">
          <SvgHandler
            xlinkHref={googlePlayIcon}
            width="135"
            height="40"
            viewBox="0 0 135 40"
            clazzName="google-play-icon"
          />
        </CustomLink>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    appStoreDownloadLink: get(
      state,
      ["qt", "config", "pagebuilder-config", "general", "appDownloadLinks", "appStoreDownloadLink"],
      ""
    ),
    googlePlayDownloadLink: get(
      state,
      ["qt", "config", "pagebuilder-config", "general", "appDownloadLinks", "googlePlayDownloadLink"],
      ""
    )
  };
};

MobileDownloadLinksBase.propTypes = {
  appStoreDownloadLink: PropTypes.string,
  googlePlayDownloadLink: PropTypes.string
};

export const MobileDownloadLinks = connect(mapStateToProps)(MobileDownloadLinksBase);
