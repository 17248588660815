/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import get from "lodash/get";

const slotSettings = {
  label: "Slot Settings",
  keyName: "slotConfig",
};
const generateSlots = (layout = "") => {
  const slotSettingForArrow = [
    "ArrowThreeColGrid",
    "ArrowThreeColSixStories",
    "ArrowThreeColSevenStory",
    "ArrowOneColStoryList",
    "ArrowElevenStories",
    "ArrowCollectionFilter",
    "ArrowFourColFiveStories",
    "ArrowTwoColSixStories",
    "ArrowThreeColFlexStories",
    "ArrowTwoColTenStoriesSidebar",
    "ArrowThreeColTwelveStories",
    "ArrowAstrologyCollection",
  ];
  if (slotSettingForArrow.includes(layout)) {
    return slotSettings;
  }
};

const generateShowHideSettings = (layout, defaultSettingsValue) => {
  const rowTitle = {
    label: "Row Title",
    type: "checkbox",
    keyName: "showRowTitle",
    defaultValue: get(defaultSettingsValue, "showRowTitle", true),
  };

  const showHideSetting = [
    {
      label: "Section Tag",
      type: "checkbox",
      keyName: "showSection",
      defaultValue: get(defaultSettingsValue, "showSection", true),
    },
    {
      label: "Author Name",
      type: "checkbox",
      keyName: "showAuthor",
      defaultValue: get(defaultSettingsValue, "showAuthor", true),
    },
    {
      label: "Published / Updated Time",
      type: "checkbox",
      keyName: "showTime",
      defaultValue: get(defaultSettingsValue, "showTime", true),
    },
    {
      label: "Time to read",
      type: "checkbox",
      keyName: "showReadTime",
      defaultValue: get(defaultSettingsValue, "showReadTime", true),
    },
    rowTitle,
  ];

  const withSeperator = {
    label: "seperator",
    type: "checkbox",
    keyName: "withSeparator",
  };

  const customRowTitle = {
    label: "Row Title",
    type: "text",
    keyName: "customCollectionName",
  };

  const rowTargetingId = {
    label: "Row Targeting ID",
    type: "text",
    keyName: "rowTargetingId",
    hint:
      "Entering the Targeting ID value here acts as an identifier to the row you would like to customize using CSS or JavaScript.",
  };

  const layoutsWithoutCustomCollectionName = [
    "ArrowTwoColTenStoriesSidebar",
    "ArrowCollectionFilter",
    "ArrowFourColSixteenStories",
    "ArrowThreeColTwelveStories",
    "ArrowAstrologyCollection",
  ];

  if (layout === "ArrowFourColSixteenStories" || layout === "ArrowThreeColTwelveStories") {
    return [...showHideSetting, withSeperator, rowTargetingId].filter(item => item.keyName !== "showRowTitle");
  }

  if (layout === "ArrowAstrologyCollection") {
    return [rowTitle, rowTargetingId];
  }

  if (layout === "ArrowElevenStories") {
    // todo: need to fix the case in eleven stories component
    return [
      ...showHideSetting,
      {
        label: "seperator",
        type: "checkbox",
        keyName: "withseparator",
      },
      customRowTitle,
      rowTargetingId,
    ];
  }
  if (layoutsWithoutCustomCollectionName.includes(layout)) {
    return [...showHideSetting, rowTargetingId];
  }
  return [...showHideSetting, customRowTitle, rowTargetingId];
};

const generateStyles = layout => {
  const styles = [
    { label: "Theme", type: "colorPicker", keyName: "theme" },
    { label: "Border", type: "dropDown", keyName: "border" },
  ];
  const theme = { label: "Theme", type: "colorPicker", keyName: "theme" };

  const border = [
    "ArrowThreeColGrid",
    "ArrowFourColGrid",
    "ArrowThreeColSixStories",
    "ArrowTwoColThreeStories",
    "ArrowCollectionFilter",
    "ArrowFourStorySlider",
    "ArrowHalfScreenSlider",
  ];

  const border1 = ["ArrowTwoColFourStories", "ArrowThreeColSevenStory", "ArrowThreeColFlexStories"];
  const border2 = ["ArrowOneColStoryList"];
  const border3 = ["ArrowTwoColSixStories", "ArrowOpinionCollection"];
  const border4 = ["ArrowSixColSixStories"];
  const dropDownOptions = [
    { label: "Default", value: "" },
    { label: "With Border", value: "full" },
  ];
  const dropDownOptions1 = [
    { label: "Default", value: "" },
    { label: "With Border", value: "bottom" },
  ];
  const dropDownOptions2 = [
    { label: "Default", value: "No Value" },
    { label: "Border Bottom", value: "bottom" },
    { label: "With Border", value: "full" },
  ];
  const dropDownOptions3 = [
    {
      label: "Default",
      value: "noBorder",
    },
    { label: "With Border", value: "fullBorder" },
  ];
  const dropDownOptions4 = [
    {
      label: "Without border",
      value: "noBorder",
    },
    { label: "Story separator", value: "fullBorder" },
    { label: "Around the story card", value: "aroundBorder" },
  ];

  if (border.includes(layout)) {
    return [
      styles[0],
      {
        ...styles[1],
        dropDownOptions: dropDownOptions,
      },
    ];
  } else if (border1.includes(layout)) {
    return [
      styles[0],
      {
        ...styles[1],
        dropDownOptions: dropDownOptions1,
      },
    ];
  } else if (border2.includes(layout)) {
    return [
      styles[0],
      {
        ...styles[1],
        dropDownOptions: dropDownOptions2,
      },
    ];
  } else if (border3.includes(layout)) {
    return [
      styles[0],
      {
        ...styles[1],
        dropDownOptions: dropDownOptions3,
      },
    ];
  } else if (border4.includes(layout)) {
    return [
      styles[0],
      {
        ...styles[1],
        dropDownOptions: dropDownOptions4,
      },
      { label: "Story Card Background Color", type: "toggle", keyName: "showCardBgColor" },
      { label: "Story Card Background", type: "cardColorPicker", keyName: "cardBgColor" },
    ];
  }

  if (layout === "ArrowTwoColFourStoryHighlight") {
    return [
      theme,
      {
        label: "Enable Separators",
        type: "checkbox",
        keyName: "showBorder",
        defaultValue: true,
      },
      {
        label: "Enable Bullets",
        type: "checkbox",
        keyName: "showBullet",
        defaultValue: true,
      },
      { label: "Choose bullet color", type: "colorPicker", keyName: "customBulletColor" },
    ];
  }
  return [theme];
};

const generateAheadNextRowMapping = layout => {
  const aheadNextVerboseComponent = { layouts: ["ArrowElevenStories"], verbose: true, defaultValue: "verbose" };
  const aheadNextContentRichComponent = {
    layouts: [
      "ArrowTwoColSixStories",
      "ArrowTwoColFourStoryHighlight",
      "ArrowThreeColFlexStories",
      "ArrowCollectionFilter",
      "ArrowFourColSixteenStories",
      "ArrowThreeColTwelveStories",
      "ArrowFourColGrid",
      "ArrowTwoColFourStories",
      "ArrowOneColStoryList",
      "ArrowSixColSixStories",
      "ArrowThreeColSevenStory",
      "ArrowOpinionCollection",
      "ArrowTwoColTenStoriesSidebar",
      "ArrowMagazineWidget",
      "ArrowAstrologyCollection",
    ],
    "content-rich": true,
    defaultValue: "content-rich",
  };
  const aheadNextPicturePressComponent = {
    layouts: [
      "ArrowTwoColThreeStories",
      "ArrowThreeColGrid",
      "ArrowHalfScreenSlider",
      "ArrowFourStorySlider",
      "ArrowFourColFiveStories",
      "ArrowThreeColSixStories",
      "ArrowFullScreenSlider",
    ],
    "picture-press": true,
    defaultValue: "picture-press",
  };

  const aheadNextWebStoriesComponent = {
    layouts: ["ArrowFourColPortraitStories"],
    webisodes: true,
    defaultValue: "webisodes",
  };

  const aheadNextMappingSettings = component => {
    const defaultLayout = component.defaultValue;

    return [
      {
        label: "Ahead Next Layouts",
        type: "radioButton",
        keyName: "aheadNextLayouts",
        radioOptions: [
          { name: "Verbose", value: "verbose", isEnabled: component["verbose"] },
          { name: "Content Rich", value: "content-rich", isEnabled: component["content-rich"] },
          { name: "Picture Press", value: "picture-press", isEnabled: component["picture-press"] },
          { name: "Web Stories", value: "webisodes", isEnabled: component["webisodes"] },
        ],
        defaultValue: defaultLayout,
        handleDisable: true,
        display: false,
      },
    ];
  };

  if (aheadNextVerboseComponent.layouts.includes(layout)) {
    return aheadNextMappingSettings(aheadNextVerboseComponent);
  } else if (aheadNextContentRichComponent.layouts.includes(layout)) {
    return aheadNextMappingSettings(aheadNextContentRichComponent);
  } else if (aheadNextPicturePressComponent.layouts.includes(layout)) {
    return aheadNextMappingSettings(aheadNextPicturePressComponent);
  } else if (aheadNextWebStoriesComponent.layouts.includes(layout)) {
    return aheadNextMappingSettings(aheadNextWebStoriesComponent);
  }
};

const generateSliderSettings = (layout, defaultSettingsValue) => {
  const sliderSettings = [
    {
      label: "Set Number of Slides to Show",
      type: "text",
      keyName: "numberOfStoriesToShow",
      placeholder: get(defaultSettingsValue, ["placeholder"], "3"),
      defaultValue: get(defaultSettingsValue, ["numberOfStoriesToShow"], 3),
    },
    {
      label: "Choose Slide Indicator",
      type: "dropDown",
      keyName: "slideIndicator",
      dropDownOptions: [
        { label: "none", value: "none" },
        { label: "dots", value: "dots" },
        { label: "dashes", value: "dashes" },
      ],
      defaultValue: get(defaultSettingsValue, ["slideIndicator"], "dots"),
    },
    {
      label: "Navigation Arrows",
      type: "checkbox",
      keyName: "navigationArrows",
      defaultValue: get(defaultSettingsValue, ["navigationArrows"], true),
    },
    {
      label: "Enable Autoplay",
      type: "checkbox",
      keyName: "isInfinite",
      defaultValue: get(defaultSettingsValue, ["isInfinite"], false),
    },
  ];
  const fullScreenSliderMargin = ["ArrowFullScreenSlider"];
  const enableMargin = {
    label: "Enable Full Width",
    type: "checkbox",
    keyName: "isFullWidth",
    defaultValue: get(defaultSettingsValue, ["isFullWidth"], false),
  };

  const sliderLayouts = ["ArrowHalfScreenSlider", "ArrowFourStorySlider"];
  if (fullScreenSliderMargin.includes(layout)) return [...sliderSettings, enableMargin];
  if (sliderLayouts.includes(layout)) return [...sliderSettings];
  return null;
};
const generateButtonSettings = layout => {
  const buttonSettings = {
    label: "Button Property",
    type: "dropDown",
    keyName: "footerButton",
  };

  const buttonText = {
    label: "Button Text",
    type: "text",
    keyName: "buttonText",
  };

  const subsequentLoadCount = {
    label: "Subsequent Load Count",
    type: "dropDown",
    keyName: "subsequentLoadCount",
  };

  const fourColOptions = [
    { label: "4", value: "4" },
    { label: "8", value: "8" },
    { label: "12", value: "12" },
    { label: "16", value: "16" },
  ];

  const threeColOptions = [
    { label: "3", value: "3" },
    { label: "6", value: "6" },
    { label: "9", value: "9" },
    { label: "12", value: "12" },
  ];

  const sixColOptions = [
    { label: "6", value: "6" },
    { label: "12", value: "12" },
    { label: "18", value: "18" },
    { label: "24", value: "24" },
  ];

  const listOptions = [
    { label: "8", value: "8" },
    { label: "12", value: "12" },
    { label: "16", value: "16" },
    { label: "20", value: "20" },
  ];

  const dropDownOptions = [
    { label: "SubsequentLoadCount", value: "SubsequentLoadCount" },
    { label: "NavigateToPage", value: "NavigateToPage" },
  ];
  const initialLoadCount = {
    label: "Initial Stories Load Count",
    type: "text",
    keyName: "initialLoadCount",
  };
  switch (layout) {
    case "ArrowThreeColGrid":
      return [
        buttonText,
        { ...initialLoadCount, placeholder: 3 },
        { ...buttonSettings, dropDownOptions },
        { ...subsequentLoadCount, dropDownOptions: threeColOptions },
      ];
    case "ArrowFourColGrid":
      return [
        buttonText,
        { ...initialLoadCount, placeholder: 4 },
        { ...buttonSettings, dropDownOptions },
        { ...subsequentLoadCount, dropDownOptions: fourColOptions },
      ];
    case "ArrowFourColPortraitStories":
      return [
        buttonText,
        { ...initialLoadCount, placeholder: 4 },
        { ...buttonSettings, dropDownOptions },
        { ...subsequentLoadCount, dropDownOptions: fourColOptions },
      ];
    case "ArrowOneColStoryList":
      return [
        buttonText,
        { ...initialLoadCount, placeholder: 6 },
        { ...buttonSettings, dropDownOptions },
        { ...subsequentLoadCount, dropDownOptions: listOptions },
      ];
    case "ArrowFourColFiveStories":
      return [
        buttonText,
        { ...initialLoadCount, placeholder: 5 },
        { ...buttonSettings, dropDownOptions },
        { ...subsequentLoadCount, dropDownOptions: fourColOptions },
      ];
    case "ArrowSixColSixStories":
      return [
        buttonText,
        { ...buttonSettings, dropDownOptions },
        { ...subsequentLoadCount, dropDownOptions: sixColOptions },
      ];
    case "ArrowThreeColFlexStories":
      return [
        buttonText,
        { ...initialLoadCount, placeholder: 12 },
        { ...buttonSettings, dropDownOptions },
        { ...subsequentLoadCount, dropDownOptions: threeColOptions },
      ];
    default:
      return [buttonText];
  }
};
const initialLoadCount = {
  label: "Initial Stories Load Count",
  type: "text",
  keyName: "initialLoadCount",
};

const initialLoadCountPlaceholder = {
  ArrowSixColSixStories: 6,
};
const generateInitialLoadCountSettings = layout => {
  return [{ ...initialLoadCount, placeholder: initialLoadCountPlaceholder[layout] }];
};

const showHideButtonSettings = (defaultSettingsValue = {}) => {
  const showButtonSettings = [
    {
      label: "Enable Button",
      type: "checkbox",
      keyName: "showButton",
      defaultValue: get(defaultSettingsValue, ["showButton"], true),
    },
  ];
  return showButtonSettings;
};

const assignRowSetting = () => {
  return [
    {
      label: "Assign Row To",
      type: "text",
      keyName: "assignRowTo",
      placeholder: "Add collection slug from Bold",
      hint:
        "Create a collection for top articles in Bold and paste the slug here. If no slug is pasted, first few stories from the issue are featured.",
    },
  ];
};

export const generateArrowSetting = (layout = "", defaultSettingsValue) => {
  const settingArray = {
    slotSettings: generateSlots(layout),
    showHideSettings: generateShowHideSettings(layout, defaultSettingsValue),
    styleSettings: generateStyles(layout),
    sliderSettings: generateSliderSettings(layout, defaultSettingsValue),
    buttonSettings: generateButtonSettings(layout),
    assignRowSetting: assignRowSetting(),
    showHideButtonSettings: showHideButtonSettings(defaultSettingsValue),
    aheadNextRowMappingSettings: generateAheadNextRowMapping(layout),
    initialLoadCountSettings: generateInitialLoadCountSettings(layout),
  };

  return settingArray;
};
