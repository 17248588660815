/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
let numberOfTrials = 0;
let eventListnerCreated = false;
const DFPHelper = () => {
  return <DFPAdReRenderer />;
};

// Component to re-render the ad, if somehow the ad didn't render.
const DFPAdReRenderer = () => {
  const pageLoading = useSelector(state => state.pageLoading);
  useEffect(() => {
    let googletag = window.googletag;
    if (!pageLoading && googletag && googletag.pubadsReady && !eventListnerCreated) {
      // Cleaning up like this as `googletag.pubads()` doesn't provide a removeEventListner function.
      eventListnerCreated = googletag.pubads().addEventListener("slotRenderEnded", function(event) {
        const slotId = event.slot.getName();
        //   Refresh the slot if the slot is empty for atleast 6 times in total.
        // we need this trials otherwise if the ad won't render, this will go inside a loop.
        if (event.isEmpty && slotId && numberOfTrials < 6) {
          setTimeout(() => {
            googletag.pubads().refresh([event.slot]);
            numberOfTrials++;
          }, 1000);
        }
      });
    }
    if (pageLoading) {
      numberOfTrials = 0;
    }
  }, [pageLoading]);
  return null;
};

export default DFPHelper;
