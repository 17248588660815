/*
 *  ************************************************************************
 *  *  © [2015 - 2021] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { useEffect, useRef } from "react";
import { PropTypes } from "prop-types";

export const StickyComponent = (props) => {
  const stickyRef = useRef(null);
  const options = {
    threshold: 1.0,
  };
  const { targetClassName = "", offsetValue = 0 } = props || {};

  const makeSticky = (entries) => {
    const targetElement = global && global.document.querySelector(`.${targetClassName}`);
    const targetInterSection = global && global.document.querySelector(`.${props.targetInterSection}`);
    const navbarElement = global && global.document.querySelector(`.navbar-sticky`);
    entries.forEach((entry) => {
      if (entry.isIntersecting) {
        targetElement.classList.remove("sticky-layer");
        navbarElement.style.height = `auto`;
      } else {
        targetElement.classList.add("sticky-layer");
        if (global.document.readyState === "complete") {
          const height = targetInterSection.clientHeight;
          const actualHeight = height + offsetValue;
          navbarElement.style.height = `${actualHeight}px`;
        }
      }
    });
  };

  useEffect(() => {
    const observer = new IntersectionObserver(makeSticky, options);

    if (stickyRef.current) {
      observer.observe(stickyRef.current);
    }
    return () => {
      if (stickyRef.current) {
        observer.unobserve(stickyRef.current);
      }
    };
  }, []);

  return <div ref={stickyRef} />;
};

StickyComponent.propTypes = {
  targetClassName: PropTypes.string,
  targetInterSection: PropTypes.string,
  offsetValue: PropTypes.number,
};
