/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import get from "lodash/get";

const breakPoints = {
  mobile: 441,
  tablet: 767,
  pc: 992
};

function breakpoint(device) {
  const foundDeviceWidth = get(breakPoints, device);
  if (foundDeviceWidth) {
    return get(global, "document.documentElement.clientWidth") <= foundDeviceWidth;
  }
  return false;
}

export { breakpoint };
