/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
// TODO: Fix this, this might fail when there is no window object

const windowAvailable = (encodedCustomCode, window) => {
  try {
    return window.decodeURI(window.atob(encodedCustomCode));
  } catch (error) {
    return "";
  }
};

export const decodeCustomCode = encodedCustomCode => {
  if (!encodedCustomCode) return "";
  if (typeof window !== "undefined") {
    return windowAvailable(encodedCustomCode, window);
  } else {
    const bufferedWidget = Buffer.from(encodedCustomCode, "base64");
    return decodeURI(bufferedWidget.toString("utf-8"));
  }
};

export const decodeURIFromWidget = decodedWidget => {
  return decodeURI(decodedWidget);
};


export const customCodeWithShortcode = (widgetValue, id) => {
  const decodeWidget = decodeCustomCode(widgetValue);
  return decodeWidget.replace(/\[unique-id]/g, id);
};



