/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
export const generateSearchPageSettings = () => {
  return {
    filterSettings: [
      { type: "checkbox", label: "Filter by Section", keyName: "section-name" },
      { type: "checkbox", label: "Filter by Author", keyName: "author-name" },
      { type: "checkbox", label: "Filter by Date", keyName: "date" },
      { type: "checkbox", label: "Filter by Story Type", keyName: "story-template" },
    ],
    slotSettings: {
      label: "Slot Settings",
      keyName: "slotConfig",
    },
    showHideSettings: [
      {
        label: "Section Tag",
        type: "checkbox",
        keyName: "showSection",
      },
      {
        label: "Author Name",
        type: "checkbox",
        keyName: "showAuthor",
      },
      {
        label: "Publish Time",
        type: "checkbox",
        keyName: "showTime",
      },
      {
        label: "Time to read",
        type: "checkbox",
        keyName: "showReadTime",
      },
    ],
    styleSettings: [
      {
        label: "Theme",
        type: "colorPicker",
        keyName: "theme",
      },
      {
        label: "Border",
        type: "dropDown",
        keyName: "border",
        dropDownOptions: [
          {
            label: "default",
            value: "No Value",
          },
          {
            label: "bottom",
            value: "bottom",
          },
          {
            label: "border",
            value: "full",
          },
        ],
      },
    ],
    sliderSettings: null,
    showHideButtonSettings: [
      {
        label: "Enable Button",
        type: "checkbox",
        keyName: "showButton",
      },
    ],
    buttonSettings: [
      {
        label: "Button Text",
        type: "text",
        keyName: "buttonText",
        placeholder: "Read More",
      },
      {
        label: "Initial Stories Load Count",
        type: "text",
        keyName: "initialLoadCount",
        placeholder: 6,
      },
      {
        label: "Button Settings",
        type: "dropDown",
        keyName: "footerButton",
        dropDownOptions: [
          {
            label: "SubsequentLoadCount",
            value: "SubsequentLoadCount",
          },
        ],
      },
      {
        label: "Subsequent Load Count",
        type: "dropDown",
        keyName: "subsequentLoadCount",
        dropDownOptions: [
          { label: "8", value: "8" },
          { label: "12", value: "12" },
          { label: "16", value: "16" },
          { label: "24", value: "24" },
        ],
      },
    ],
  };
};
