/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

/**
 * Adds attribute target="_blank" to all hyperlinks within `el`
 * to open those links in a new tab
 *
 * @param {HTMLElement|null} el This is a React ref to the story wrapper element
 */
export function addTargetBlankToExtLinks(el) {
  if (!el) return;
  const documentNodes = el.getElementsByTagName("a") || [];
  const anchors = Array.from(documentNodes);
  anchors.forEach(anchor => {
    if (anchor.hostname && anchor.hostname !== window.location.hostname) {
      anchor.setAttribute("target", "_blank");
    }
  });
}
