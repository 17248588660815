/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import {
  ListicleStoryTemplate,
  LiveBlogStoryTemplate,
  PhotoStoryTemplates,
  VideoStoryTemplate,
  TextStoryTemplate,
} from "@quintype/arrow";

import { UpdateOnInterval } from "@quintype/components";
import get from "lodash/get";
import PropTypes from "prop-types";
import React from "react";
import { StoryFooter } from "../molecules/story-footer";
import { getStoryDataWithAdSlots } from "./get-story";

const wrapper =
  (StoryComponent) =>
  ({
    story,
    templateConfig,
    preview,
    storyElementsConfig,
    adComponent,
    widgetComp,
    enableDarkMode,
    loadRelatedStories,
  }) => {
    return (
      <StoryComponent
        story={story}
        config={templateConfig}
        storyElementsConfig={storyElementsConfig}
        adComponent={adComponent}
        widgetComp={widgetComp}
        enableDarkMode={enableDarkMode}
        loadRelatedStories={loadRelatedStories}
        secondChild={
          !preview && (
            <StoryFooter
              story={story}
              isInfiniteScrollEnabled={get(templateConfig, ["isStoryInfiniteScrollEnabled"], false)}
            />
          )
        }
      />
    );
  };

const StoryTemplate = ({
  subPageType,
  story,
  templateConfig,
  preview,
  storyElementsConfig,
  adComponent,
  widgetComp,
  enableDarkMode,
  loadRelatedStories,
}) => {
  switch (subPageType) {
    case "video":
      return wrapper(VideoStoryTemplate)({
        story,
        templateConfig,
        preview,
        storyElementsConfig,
        adComponent,
        widgetComp,
        enableDarkMode,
        loadRelatedStories,
      });
    case "photo":
      return wrapper(PhotoStoryTemplates)({
        story,
        templateConfig,
        preview,
        storyElementsConfig,
        adComponent,
        widgetComp,
        enableDarkMode,
        loadRelatedStories,
      });
    case "listicle":
      return wrapper(ListicleStoryTemplate)({
        story,
        templateConfig,
        preview,
        storyElementsConfig,
        adComponent,
        widgetComp,
        enableDarkMode,
        loadRelatedStories,
      });
    case "live-blog":
      return wrapper(LiveBlogStoryTemplate)({
        story,
        templateConfig,
        preview,
        storyElementsConfig,
        adComponent,
        widgetComp,
        enableDarkMode,
        loadRelatedStories,
      });
    case "text":
    default:
      return wrapper(TextStoryTemplate)({
        story,
        templateConfig,
        preview,
        storyElementsConfig,
        adComponent,
        widgetComp,
        enableDarkMode,
        loadRelatedStories,
      });
  }
};

const PublicStory = ({
  story,
  templateConfig,
  preview,
  storyElementsConfig,
  adComponent,
  widgetComp,
  storyBaseType,
  enableDarkMode,
  loadRelatedStories,
  config
}) => {
  const sketchesHost = get(config, ["sketches-host"], "");
  const subPageType = storyBaseType || story["story-template"];
  const storyId = get(story, ["id"], "");
  const { storySlots = [] } = templateConfig;

  const storyDataLoader = () => getStoryDataWithAdSlots(storyId, storySlots, sketchesHost);

  if (subPageType === "live-blog") {
    return (
      <UpdateOnInterval dataLoader={storyDataLoader} interval={20000} initData={{ story: story }}>
        {({ data }) => {
          const { story } = data;
          return StoryTemplate({
            subPageType,
            story,
            templateConfig,
            preview,
            storyElementsConfig,
            data,
            adComponent,
            widgetComp,
            enableDarkMode,
            loadRelatedStories,
          });
        }}
      </UpdateOnInterval>
    );
  } else {
    return StoryTemplate({
      subPageType,
      story,
      templateConfig,
      preview,
      storyElementsConfig,
      adComponent,
      widgetComp,
      enableDarkMode,
      loadRelatedStories,
    });
  }
};

PublicStory.propTypes = {
  story: PropTypes.object,
  templateConfig: PropTypes.object,
  preview: PropTypes.bool,
  storyElementsConfig: PropTypes.object,
  storyBaseType: PropTypes.string,
};

export default PublicStory;
