/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import get from "lodash/get";
import { getFilteredMagazineCollection } from "../../utils";

export const loadRelatedStories = (story, mountAtPrefix) => {
  const FIELDS =
    "id,metadata,story-template,headline,slug,hero-image-s3-key,hero-image-metadata,author-name,author-id,authors,url,alternative,last-published-at,first-published-at,hero-image-caption";
  return getFilteredMagazineCollection(mountAtPrefix, story.id)
    .then(({ issueSlug, magazineSlug }) => {
      if (magazineSlug && issueSlug) {
        return fetch(`${mountAtPrefix}/api/v1/magazines/${magazineSlug}/issues/${issueSlug}`)
          .then((response) => response.json())
          .then((response) => {
            const magazineRelatedStories = get(response, ["data", "items"], [])
              .filter((item) => item?.type === "story" && item?.id !== story?.id)
              .slice(0, 4);
            return { relatedStories: magazineRelatedStories };
          })
          .catch((err) => {
            console.error("Error in retrieving related stories :", err);
            return [];
          });
      } else {
        const sectionQuery = (story?.sections?.length && `section-id=${story.sections[0]?.id}`) || "";
        return fetch(`${mountAtPrefix}/api/v1/stories/${story?.id}/related-stories?${sectionQuery}&fields=${FIELDS}`)
          .then((response) => response.json())
          .then((response) => {
            return { relatedStories: get(response, ["related-stories"], []) };
          })
          .catch((err) => {
            console.error("Error in retrieving related stories :", err);
            return [];
          });
      }
    })
    .catch((err) => console.log("error", err));
};
