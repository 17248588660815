/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import "./link.m.css";

const FooterLinkBase = (props) => {
  const footerMenu = props.menu.filter((item) => item["menu-group-slug"] === "footer");
  const footerType = props.footerTheme === "footer_1" ? "footer-one" : "footer";
  return (
    <ul styleName={`${footerType} menu`}>
      {footerMenu.map((item) => {
        return (
          <li data-testid="footer-menu-item" key={item.id}>
            <a href={item.completeUrl}>{item.title}</a>
          </li>
        );
      })}
    </ul>
  );
};

const mapStateToProps = (state) => {
  return {
    menu: get(state.qt, ["data", "navigationMenu"], []),
    footerTheme: get(state.qt, ["config", "pagebuilder-config", "footer", "footerStyle"], "footer_1"),
  };
};

FooterLinkBase.propTypes = {
  menu: PropTypes.object,
  footerTheme: PropTypes.string,
};

export const FooterLink = connect(mapStateToProps)(FooterLinkBase);
