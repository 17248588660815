/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { useEffect } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import { UserProfile } from "./user-profile";

const HeaderLoginBase = (props) => {
  const { headerTheme, enableSSO, localizedElementsData = {}, member, memberLoaded, sso = {}, sketchesHost } = props;
  const { signInSignUp = {} } = localizedElementsData;
  const { signIn: localizedSignIn } = signInSignUp;
  const headerType = headerTheme.includes("single-layer") ? "single-layer" : "";

  useEffect(() => {
    if (enableSSO && !member) updateMember();
  }, [member]);

  const updateMember = async () => {
    const { currentUser } = await import("@quintype/bridgekeeper-js");

    try {
      const { user, error } = await currentUser();
      if (user || error) memberLoaded(true);
      if (user?.["verification-status"]) {
        props.updateMember(user);
      }
    } catch (err) {
      console.log(err);
    }
  };

  if (!enableSSO) return null;
  return <UserProfile member={member} customCssClasses={headerType} localizedSignIn={localizedSignIn} sso={sso} sketchesHost={sketchesHost} />;
};

HeaderLoginBase.propTypes = {
  headerTheme: PropTypes.string,
  sketchesHost: PropTypes.string,
  enableSSO: PropTypes.bool,
  localizedElementsData: PropTypes.shape({
    buttonLabels: PropTypes.shape({
      signIn: PropTypes.string,
    }),
  }),
  updateMember: PropTypes.func,
  member: PropTypes.object,
  sso: PropTypes.object,
};

const mapStateToProps = (state) => {
  const { enableLocalization = false, localizedElements = {} } = get(
    state.qt,
    ["config", "pagebuilder-config", "general", "localization"],
    {}
  );
  return {
    headerTheme: get(state.qt, ["config", "pagebuilder-config", "header", "headerStyle"], "header_1"),
    enableSSO: get(state.qt, ["config", "pagebuilder-config", "header", "enableSSO"], false),
    localizedElementsData: enableLocalization ? localizedElements : {},
    sso: get(state.qt, ["config", "pagebuilder-config", "general", "sso"], {}),
    sketchesHost: get(state.qt, ["config", "sketches-host"]),
    member: get(state, ["member"], null),
  };
};

const mapDispatchToProps = (dispatch) => ({
  updateMember: (member) => dispatch({ type: "MEMBER_UPDATED", member }),
  memberLoaded: (memberLoaded) => dispatch({ type: "MEMBER_LOADED", memberLoaded }),
});

export const HeaderLogin = connect(mapStateToProps, mapDispatchToProps)(HeaderLoginBase);

export { HeaderLoginBase };
