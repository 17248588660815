/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import { WidgetComponent } from "../../Widget";
import { customCodeWithShortcode } from "../../../../utils/decodeCustomCode";
import "./widget.module.css";

function CustomWidgetRow({ metadata, storyId }) {
  const { customCode: codeToInject, targetingId } = get(metadata, ["config"], {});
  if (!codeToInject) return null;
  let id;
  if (targetingId && storyId) {
    id = `widget-${targetingId}-${storyId}`;
  } else {
    id = `widget-${targetingId || get(metadata, ["rowId"], "")}`;
  }

  const newId = `custom-${id}`;
  const widgetCode = customCodeWithShortcode(codeToInject, newId);
  return (
    <div id={id} styleName="customWidgetWrapper">
      <WidgetComponent widgetValue={`<div>${widgetCode}</div>`} />
    </div>
  );
}

CustomWidgetRow.propTypes = {
  metadata: PropTypes.object,
  storyId: PropTypes.string,
};
export { CustomWidgetRow };
