/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import { connect } from "react-redux";
import React from "react";
import get from "lodash/get";
import PropTypes from "prop-types";
import { MenuLink } from "../../../menu-link.js";
import { updateBodyScroll } from "../../../../utils";
import "./menu.m.css";

class MenuBase extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeChild: null,
    };
    this.toggleSubmenu = this.toggleSubmenu.bind(this);
    this.closeAll = this.closeAll.bind(this);
  }

  closeAll = () => {
    updateBodyScroll(false, "remove-body-scroll-header");
    this.props.closeAll();
  };

  toggleSubmenu(e) {
    e.stopPropagation();
    e.preventDefault();
    const currentChild = Number(e.currentTarget.dataset.id);
    this.setState(function (prevState) {
      return {
        activeChild: prevState.activeChild === currentChild ? null : currentChild,
      };
    });
  }

  getMenuTypeLink(item, index) {
    if (item["item-type"] === "placeholder") {
      return <span data-id={index}>{item.title}</span>;
    }
    return <MenuLink callback={this.closeAll} href={item.completeUrl} item={item} />;
  }

  render() {
    const { menu, count, languageDirection = "ltr", primarySubmenu } = this.props;
    return (
      <ul data-menu="true" styleName="menu">
        {menu.slice(0, count).map((item, index) => {
          if (item.children.length) {
            return (
              <li
                data-id={index}
                styleName={`menu-item has-child ${index === this.state.activeChild ? "submenu-open" : ""}`}
                key={`${item["id"]}${index}`}
              >
                {this.getMenuTypeLink(item, index)}
                <span styleName="arrow" onClick={this.toggleSubmenu} data-id={index}>
                  <span styleName="icon-arrow">&#8964;</span>
                </span>
                <ul
                  data-submenu="true"
                  styleName={`sub-menu ${index === this.state.activeChild ? "open" : "close"} ${
                    languageDirection === "rtl" ? "sub-menu-rtl" : ""
                  } ${index === primarySubmenu ? "open-submenu" : ""}`}
                >
                  {item.children.map((child, index) => {
                    return (
                      <li styleName="sub-menu-item" key={`${child["id"]}${index}`}>
                        <MenuLink callback={this.closeAll} item={child} href={child.url} />
                      </li>
                    );
                  })}
                </ul>
              </li>
            );
          }

          return (
            <li styleName="menu-item" key={`${item["id"]}${index}`}>
              {item["item-type"] === "placeholder" ? (
                <span>{item.title}</span>
              ) : (
                <MenuLink callback={this.closeAll} item={item} />
              )}
            </li>
          );
        })}
      </ul>
    );
  }
}

MenuBase.propTypes = {
  menu: PropTypes.object,
  count: PropTypes.number,
  closeAll: PropTypes.func,
  primarySubmenu: PropTypes.number,
  openPrimarySubmenu: PropTypes.func,
  closePrimarySubmenu: PropTypes.func,
  languageDirection: PropTypes.string,
  config: PropTypes.object,
};

const mapStateToProps = (state) => {
  const menu = () => {
    const { navigationMenu = [] } = get(state.qt, ["data"], {});
    const { domainSlug = "" } = get(state.qt, ["config"], {});
    const filteredMenu = domainSlug
      ? navigationMenu.filter((item) => item["menu-group-slug"] === `footer-menu-${domainSlug}`)
      : navigationMenu.filter((item) => item["menu-group-slug"] === "footer-site-links");
    return filteredMenu;
  };

  return {
    primarySubmenu: get(state, ["footer", "primarySubmenu"]),
    menu: menu(),
    languageDirection: get(state.qt, ["config", "language", "direction"], "ltr"),
  };
};

const mapDispatchToProps = (dispatch) => ({
  openPrimarySubmenu: (menuId) => dispatch({ type: "OPEN_PRIMARY_SUBMENU", payload: { menuId } }),
  closePrimarySubmenu: () => dispatch({ type: "CLOSE_PRIMARY_SUBMENU" }),
  closeAll: () => dispatch({ type: "CLOSE_ALL" }),
});

export const Menu = connect(mapStateToProps, mapDispatchToProps)(MenuBase);
