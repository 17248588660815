/*
 *  ************************************************************************
 *  *  © [2015 - 2024] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from 'react';
import './banner.m.css';
import { getLoginLink } from '../../pages/login/sso-utils';
import { getPath, setSubscriptionRedirect } from '../../../utils';
import { useSelector } from 'react-redux';
import { get } from 'lodash';

export function getPaywallButtons (
  buttonType,
  buttonTxt = '',
  buttonColor,
  buttonTextColor,
  member,
  sso,
  localizedMagazineSubscription,
  magazineSlug,
  isPbPreview
) {
  const primaryButtonStyle = 'banner-button banner-primary-button';
  const secondaryButtonStyle = 'banner-button banner-magazine-button';
  const sketchesHost = useSelector(state =>
    get(state, ['qt', 'config', 'sketches-host'], '')
  );

  if (isPbPreview) {
    return (
      <div
        className={primaryButtonStyle}
        styleName={primaryButtonStyle}
        style={{
          backgroundColor: buttonColor,
          color: buttonTextColor === 'dark' ? '#000' : '#fff'
        }}
      >
        {buttonTxt}
      </div>
    );
  }

  if (buttonType === 'subscribe') {
    const subscriptionUrl = getPath(sketchesHost, '/subscription');
    return (
      <div>
        {magazineSlug &&
          getMagazineSubcriptionButton(
            magazineSlug,
            localizedMagazineSubscription,
            secondaryButtonStyle
          )}
        <div
          styleName={primaryButtonStyle}
          style={{
            backgroundColor: buttonColor,
            color: buttonTextColor === 'dark' ? '#000' : '#fff'
          }}
          onClick={() => setSubscriptionRedirect(subscriptionUrl, sketchesHost)}
        >
          {buttonTxt || 'Subscribe'}
        </div>
      </div>
    );
  }

  return (
    <div
      styleName={primaryButtonStyle}
      onClick={e => getLoginLink(member, sso, sketchesHost)}
      style={{
        backgroundColor: buttonColor,
        color: buttonTextColor === 'dark' ? '#000' : '#fff'
      }}
    >
      {buttonTxt || 'Sign in'}
    </div>
  );
}

function getMagazineSubcriptionButton (
  magazineSlug,
  localizedMagazineSubscription,
  secondaryButtonStyle
) {
  const sketchesHost = useSelector(state =>
    get(state, ['qt', 'config', 'sketches-host'], '')
  );
  const groupSubscriptionUrl = getPath(
    sketchesHost,
    `/subscription?group=${magazineSlug}`
  );
  return (
    <div
      styleName={secondaryButtonStyle}
      onClick={() =>
        setSubscriptionRedirect(groupSubscriptionUrl, sketchesHost)
      }
    >
      {localizedMagazineSubscription || 'Magazine Subscription'}
    </div>
  );
}
