/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import get from "lodash/get";

import { SvgHandler } from "../../svg-handler/svg-handler";
import googlePlayIcon from "../../../../assets/icons/download-links/googleplay-small.svg";
import appStoreIcon from "../../../../assets/icons/download-links/appstore-small.svg";

import "./app-download-links.m.css";

export const AppDownloadLinks = () => {
  const { appStoreDownloadLink = "", googlePlayDownloadLink = "" } = useSelector((state) =>
    get(state, ["qt", "config", "pagebuilder-config", "general", "appDownloadLinks"], {})
  );

  const getIcons = (link, icon, className) => {
    return (
      <a href={link} target="_blank" rel="noopener noreferrer" data-testid={className}>
        <SvgHandler xlinkHref={icon} width="24" height="24" viewBox="0 0 24 24" clazzName={className} />
      </a>
    );
  };

  return (
    <div styleName="wrapper" className="app-download-links">
      {getIcons(googlePlayDownloadLink, googlePlayIcon, "google-play-small-icon")}
      {getIcons(appStoreDownloadLink, appStoreIcon, "app-store-small-icon")}
    </div>
  );
};

AppDownloadLinks.propTypes = {
  appStoreDownloadLink: PropTypes.string,
  googlePlayDownloadLink: PropTypes.string,
};
