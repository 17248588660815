/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */

export const generateMagazineHeaderSettings = () => {
  let rowSettings = [
    { label: "Background Color", type: "colorPicker", keyName: "theme" },
    {
      label: "Subscribe Button",
      type: "checkbox",
      keyName: "showButton",
    },
    {
      label: "Button Text",
      type: "text",
      keyName: "buttonText",
    },
  ];
  return { settings: rowSettings };
};

export const generateMagazineEditionsSettings = () => {
  let rowSettings = [
    {
      type: "radioButton",
      label: "Sort by",
      keyName: "sortBy",
      radioOptions: [
        {
          name: "Latest Issues First",
          value: "latest-issues",
        },
        {
          name: "Oldest Issues First",
          value: "older-issues",
        },
      ],
    },
    {
      label: "Issue label",
      type: "radioButton",
      keyName: "editionNameFormat",
      radioOptions: [
        { name: "Issue Date", value: "magazineDate" },
        { name: "Issue Name", value: "magazineTitle" },
      ],
    },
    { label: "Background Color", type: "colorPicker", keyName: "theme" },
    { label: "Show Row Title", type: "checkbox", keyName: "showRowTitle" },
    { label: "Row title", type: "text", keyName: "rowTitle" },
    {
      label: "Initial Load Count",
      type: "dropDown",
      keyName: "initialLoadCount",
      dropDownOptions: [
        {
          label: "8",
          value: "8",
        },
        {
          label: "12",
          value: "12",
        },
        {
          label: "16",
          value: "16",
        },
        {
          label: "20",
          value: "20",
        },
      ],
    },
    {
      label: "Load More Load Count",
      type: "dropDown",
      keyName: "subsequentLoadCount",
      dropDownOptions: [
        {
          label: "8",
          value: "8",
        },
        {
          label: "12",
          value: "12",
        },
        {
          label: "16",
          value: "16",
        },
        {
          label: "20",
          value: "20",
        },
      ],
    },
  ];
  return { settings: rowSettings };
};

export const generateMagazineWidgetSettings = () => {
  const showHideSettings = [
    {
      label: "Row Title",
      type: "checkbox",
      keyName: "showRowTitle",
    },
    {
      label: "Author Name",
      type: "checkbox",
      keyName: "showAuthor",
    },
    {
      label: "Publish Time",
      type: "checkbox",
      keyName: "showTime",
    },
    {
      label: "Time to read",
      type: "checkbox",
      keyName: "showReadTime",
    },
    {
      label: "Row Targeting ID",
      type: "text",
      keyName: "rowTargetingId",
      hint: "Entering the Targeting ID value here acts as an identifier to the row you would like to customize using CSS or JavaScript.",
    },
  ];
  const styleSettings = [
    { label: "Theme", type: "colorPicker", keyName: "theme" },
    {
      label: "Border",
      type: "dropDown",
      keyName: "border",
      dropDownOptions: [
        { label: "Default", value: "" },
        { label: "With Border", value: "borderBottom" },
      ],
    },
  ];

  const showHideButtonSettings = [
    {
      label: "Enable Button",
      type: "checkbox",
      keyName: "showButton",
      defaultValue: true
    },
  ];

  const buttonSettings = [
    {
      label: "Button Text",
      type: "text",
      keyName: "buttonText",
      placeholder: "Read More",
    },
  ];

  return { showHideSettings, styleSettings, buttonSettings, showHideButtonSettings };
};
