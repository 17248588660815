/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { Component } from "react";
import PropTypes from "prop-types";
import * as utils from "../utils/raf";
import "./marquee_landscape.module.css";

utils.raf();

export default class Marquee extends Component {
  constructor(props) {
    super(props);
    this.timerMarquee = null;
    this.landscapeItemRef = React.createRef();
    this.landscapeWrapperRef = React.createRef();
    this.landscapeItemAltRef = React.createRef();
    this.state = {};
  }

  initMarquee() {
    this.stopMarquee();
    this.runMarquee();
  }

  landscapeMarquee() {
    /** refer https://github.com/quintype/page-builder/issues/1994#issuecomment-1245319156
     ** for right -> left animation for rtl
     **/
    // below code implements left -> right animation  for rtl

    if (this.landscapeWrapperRef.current && this.landscapeItemRef.current) {
      const { scrollSpeed = 1 } = this.props;
      if (this.landscapeItemRef.current.offsetWidth > this.landscapeWrapperRef.current.offsetWidth) {
        this.landscapeItemAltRef.current.style.display = "flex";
      }
      // for rtl, this.landscapeWrapperRef.current.scrollLeft is -ve , so used Math.abs
      if (Math.abs(this.landscapeWrapperRef.current.scrollLeft) >= this.landscapeItemRef.current.scrollWidth) {
        this.landscapeWrapperRef.current.scrollLeft = 0;
      } else {
        if (this.props.pageDirection === "ltr") {
          this.landscapeWrapperRef.current.scrollLeft = this.landscapeWrapperRef.current.scrollLeft + scrollSpeed;
        } else {
          // for rtl scrollbar will be on the right, so we have to subtract scrollspeed to move it
          // refer mdn for more details on scrollLeft behaviour for rtl
          this.landscapeWrapperRef.current.scrollLeft = this.landscapeWrapperRef.current.scrollLeft - scrollSpeed;
        }
      }
      this.timerMarquee = requestAnimationFrame(() => this.landscapeMarquee());
    }
  }

  runMarquee() {
    this.stopMarquee();
    this.timerMarquee = requestAnimationFrame(() => this.landscapeMarquee());
  }

  stopMarquee() {
    this.timerMarquee && cancelAnimationFrame(this.timerMarquee);
  }

  componentDidMount() {
    this.initMarquee();
    this.addDividerClass();
    const { getMarquee } = this.props;
    getMarquee &&
      getMarquee({
        runMarquee: this.runMarquee,
        stopMarquee: this.stopMarquee,
      });
  }

  componentWillUnmount() {
    this.stopMarquee();
  }

  addDividerClass() {
    if (this.landscapeItemRef.current && this.landscapeWrapperRef.current) {
      if (this.landscapeItemRef.current.offsetWidth < this.landscapeWrapperRef.current.offsetWidth) {
        this.landscapeItemRef.current.classList.add("marquee-landscape-exceed");
      }
    }
  }

  getDelimiterClass(delimiter) {
    switch (delimiter) {
      case "pipe":
        return "pipe-delimiter";
      default:
        return "dot-delimiter";
    }
  }

  render() {
    const { loopData = [], delimiter = "" } = this.props;
    return (
      <div className="react-marquee-box">
        <div
          className="marquee-landscape-wrap"
          onMouseEnter={() => this.stopMarquee()}
          onMouseLeave={() => this.runMarquee()}
          ref={this.landscapeWrapperRef}
        >
          <div className="marquee-landscape-item" ref={this.landscapeItemRef}>
            {loopData.map((item, index) => (
              <div className={`marquee-container ${this.getDelimiterClass(delimiter)}`} key={index}>
                {item}
              </div>
            ))}
          </div>
          <div className="marquee-landscape-item marquee-landscape-item-alt" ref={this.landscapeItemAltRef}>
            {loopData.map((item, index) => (
              <div className={`marquee-container ${this.getDelimiterClass(delimiter)}`} key={index}>
                {item}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

Marquee.propTypes = {
  loop: PropTypes.bool,
  loopData: PropTypes.array,
  getMarquee: PropTypes.func,
  scrollSpeed: PropTypes.number,
  delimiter: PropTypes.string,
  pageDirection: PropTypes.string
};
