/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React, { useState } from "react";
import PropTypes from "prop-types";
import get from "lodash/get";
import {
  filterCollectionStories,
  getCollectionStories,
  getAuthorStories,
  tagPageStories,
  getDynamicAssets,
} from "../../utils";
import { useSelector } from "react-redux";

const WithSubsequentLoad = (Component, defaultLoadCount = 3, hideFirstCard = false) => {
  const WrappedComponent = ({ collection, config = {}, index }) => {
    const mountAtPrefix = useSelector((state) => get(state, ["qt", "config", "mountAt"], ""));
    const pageType = useSelector((state) => get(state, ["qt", "pageType"], ""));
    const initialLoadCount = parseInt(get(config, ["initialLoadCount"], defaultLoadCount));
    const collectionStories = filterCollectionStories(collection.items);
    const initialCollectionItems = collectionStories.slice(0, initialLoadCount);
    const [collectionItems, setCollectionItems] = useState(initialCollectionItems);
    const [isLoading, setIsLoading] = useState(false);
    const intialLoadMoreButtonState = initialCollectionItems.length >= initialLoadCount;
    const [isLoadMoreVisible, setIsLoadMoreVisible] = useState(intialLoadMoreButtonState);
    const updatedCollection = { ...collection, items: collectionItems };
    const tagSlugs = useSelector((state) => get(state, ["qt", "data", "tagSlug"], ""));
    const author = useSelector((state) => get(state, ["qt", "data", "author"], {}));
    const [sectionPageOffset, setSectionPageOffset] = useState(collection.currentOffset);
    const memberSubscriptions = useSelector((state) => get(state, ["memberSubscriptions"], []));

    const pbConfig = useSelector((state) => get(state, ["qt", "config", "pagebuilder-config"], {}));
    const lightRows = get(pbConfig, ["home", "lightRows"], false);

    const getMoreStories = async (offset, limit) => {
      setIsLoading(true);
      let response;
      switch (pageType) {
        case "author-page":
          const authorId = get(author, ["id"], "");
          response = await getAuthorStories(authorId, offset, limit, mountAtPrefix);
          break;
        case "tag-page":
          const storyFields =
            "slug,metadata,story-template,story-content-id,id,headline,alternative,hero-image-s3-key,hero-image-metadata,sections,tags,author-name,author-id,authors,created-at,first-published-at,published-at,last-published-at,url,read-time";
          const tagStoriesData = await tagPageStories(offset, limit, mountAtPrefix, tagSlugs, storyFields);
          const tagItems = tagStoriesData.stories.map((item) => {
            return { story: item, type: "story" };
          });
          response = { items: tagItems };
          break;
        case "purchased-details":
          response = { items: await getDynamicAssets(memberSubscriptions, offset, limit, "SubsequentLoad") };
          break;
        default:
          offset = sectionPageOffset || offset;

          response = await getCollectionStories(collection.slug, offset, limit, mountAtPrefix);
          setSectionPageOffset(parseInt(sectionPageOffset) + parseInt(limit));
      }
      if (response.items.length) {
        setCollectionItems([...collectionItems, ...response.items]);
      } else {
        setIsLoadMoreVisible(false);
      }
      setIsLoading(false);
    };

    const isIsolatedLoadMore = () =>
      lightRows && pageType === "home-page" && config.footerButton === "SubsequentLoadCount";

    return (
      <Component
        collection={updatedCollection}
        config={config}
        getMoreStories={getMoreStories}
        isLoadMoreVisible={isLoadMoreVisible}
        isLoading={isLoading}
        index={index}
        isolatedLoadMore={isIsolatedLoadMore()}
        hideFirstCard={hideFirstCard}
      />
    );
  };

  WrappedComponent.propTypes = {
    collection: PropTypes.object.isRequired,
    config: PropTypes.object.isRequired,
    index: PropTypes.number
  };

  return WrappedComponent;
};

export default WithSubsequentLoad;
