/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
const generateSettings = layout => {
  let showHideSetting;
  if (layout === "ArrowPageIntroductionCard") {
    showHideSetting = [
      { label: "Author List Title", type: "text", keyName: "pageTitle", placeholder: "Our Authors" },
      {
        label: "Author List Description",
        type: "textArea",
        keyName: "pageDescription",
        placeholder: ""
      },
      { label: "Theme", type: "colorPicker", keyName: "theme" },
      { label: "Subscribe Button", type: "checkbox", keyName: "showButton" },
      {
        label: "Button Text",
        type: "text",
        keyName: "buttonText"
      }
    ];
    return showHideSetting;
  } else {
    showHideSetting = [
      { label: "Theme", type: "colorPicker", keyName: "theme" },
      {
        label: "Border",
        type: "checkbox",
        keyName: "borderSupport"
      },
      {
        label: "Initial Author Count",
        type: "text",
        keyName: "initialLoadCount"
      },
      {
        label: "Load Author count",
        type: "text",
        keyName: "subsequentLoadCount"
      },
      {
        label: "Enable Author Bio",
        type: "checkbox",
        keyName: "enableBio"
      },
      {
        label: "Enable Social Links",
        type: "checkbox",
        keyName: "enableSocialLinks"
      }
    ];
    return showHideSetting;
  }
};

export const generateAuthorsPageSettings = layout => {
  const settingArray = {
    settings: generateSettings(layout)
  };
  return settingArray;
};
