/*
 *  ************************************************************************
 *  *  © [2015 - 2021] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import { SocialFollow } from "../../../basic/social-follow/social-follow";
import { getTextContrast } from "../../../../utils";

const SocialFollowWrapperBase = ({
  socialFollowStyle,
  socialLinks,
  customCssClasses,
  footerBgColor,
  darkFooterBgColor,
  enableDarkMode,
}) => {
  return (
    <SocialFollow
      hasBackground={true}
      contrastAgainstBg={getTextContrast(enableDarkMode ? darkFooterBgColor : footerBgColor)}
      socialStyle={socialFollowStyle}
      socialLinks={socialLinks}
      color="#000"
      customCssClasses={customCssClasses}
    />
  );
};

const mapStateToProps = (state) => {
  return {
    socialFollowStyle: get(
      state,
      ["qt", "config", "pagebuilder-config", "footer", "socialFollowStyle"],
      "circular-color-svg"
    ),
    customCssClasses: "",
    footerBgColor: get(state, ["qt", "config", "pagebuilder-config", "footer", "backgroundColor"], ""),
    darkFooterBgColor: get(state, ["qt", "config", "pagebuilder-config", "footer", "darkBackgroundColor"], "#2d2d2d"),
    enableDarkMode: get(state, ["header", "isDarkModeEnabled"], false),
    socialLinks: get(state, ["qt", "config", "social-links"], {}),
  };
};

SocialFollowWrapperBase.propTypes = {
  socialLinks: PropTypes.object,
  customCssClasses: PropTypes.string,
  footerBgColor: PropTypes.string,
  socialFollowStyle: PropTypes.string,
};

export const SocialFollowWrapper = connect(mapStateToProps)(SocialFollowWrapperBase);
