/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
/* eslint-disable no-unused-vars */
import camelCase from "lodash/camelCase";
import get from "lodash/get";
import PropTypes from "prop-types";
import { useEffect } from "react";
import { useDispatch, connect } from "react-redux";
import { defaultTheme, defaultDarkTheme } from "../../constants";

import canUseDom from "../../../utils/can-use-dom";

const PBThemeSetting = (props) => {
  const supportedPages = {
    "home-page": "home",
    "section-page": "section",
    "collection-page": "section",
    "tag-page": "tag",
    "author-page": "author",
    "pagebuilder-author-preview-page": "author",
    "pagebuilder-tag-preview-page": "tag",
    "pagebuilder-section-preview-page": "section",
    "pagebuilder-collection-preview-page": "section",
    "pagebuilder-story-preview-page": "story",
    "pagebuilder-authors-preview-page": "authors",
    "authors-page": "authors",
    "issue-landing-page": "issue-landing",
    "magazine-landing-page": "magazine-landing",
    "magazine-archive-page": "archive",
    "": "",
  };
  const dispatch = useDispatch();
  const subPageType = props.subPageType ? camelCase(props.subPageType) : "text";
  const storyField = ["text-story", "photo-story", "video-story", "liveBlog-story", "listicle-story"].includes(
    `${subPageType}-story`
  )
    ? "story"
    : "customStory";
  const magazinePage =
    props.pageType === "magazine-landing-page" ||
    props.pageType === "issue-landing-page" ||
    props.pageType === "magazine-archive-page";
  const updatedPath = !magazinePage ? [supportedPages[props.pageType]] : ["magazine", supportedPages[props.pageType]];
  const theme = get(props.pbConfig, [storyField, `${subPageType}-story`, "settings", "theme"], defaultTheme);
  const darkTheme = get(
    props.pbConfig,
    [storyField, `${subPageType}-story`, "settings", "darkTheme"],
    defaultDarkTheme
  );
  const storyPageColor = props.enableDarkMode ? darkTheme : theme;
  const bgColor = get(props.pbConfig, [...updatedPath, "pageSetting", "bgColor"], defaultTheme);
  const darkBgColor = get(props.pbConfig, [...updatedPath, "pageSetting", "darkBgColor"], defaultDarkTheme);
  const getPageBgColor = props.enableDarkMode ? darkBgColor : bgColor;
  const pageBgColor = props.pageType === "story-page" ? storyPageColor : getPageBgColor;
  const pbEnableDarkMode = get(props.pbConfig, ["header", "showDarkModeToggle"], false);

  useEffect(() => {
    const darkModeEnabled = !pbEnableDarkMode ? false : props.enableDarkMode;
    dispatch && dispatch({ type: "TOGGLE_DARK_MODE", isDarkModeEnabled: darkModeEnabled });
  }, []);

  useEffect(() => {
    if (canUseDom && pageBgColor) {
      document.getElementsByTagName("body")[0].style.backgroundColor = pageBgColor;
    }
    return function cleanup() {
      if (canUseDom) {
        document.getElementsByTagName("body")[0].style.backgroundColor = "initial";
      }
    };
  });
  return null;
};

PBThemeSetting.propTypes = {
  pageTypes: PropTypes.string,
};

const mapStatetoProps = (state) => {
  return {
    enableDarkMode: get(state, ["header", "isDarkModeEnabled"], false),
    pbConfig: get(state, ["qt", "config", "pagebuilder-config"], {}),
    pageType: get(state, ["qt", "pageType"], ""),
    subPageType: get(state, ["qt", "subPageType"], get(state, ["qt", "data", "subPageType"], "")),
  };
};

export const PBThemeSettings = connect(mapStatetoProps)(PBThemeSetting);
