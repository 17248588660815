/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import get from "lodash/get";
import React, { useState, useEffect, Suspense, lazy } from "react";
import canUseDOM from "../../../../utils/can-use-dom";
import { connect } from "react-redux";
import { isEmpty } from "../../../utils";
import PropTypes from "prop-types";

function MetypeBase(props) {
  const { "publisher-name": publisherName, "pagebuilder-config": pbConfig } = props.config;

  const metypeConfig = get(pbConfig, ["general", "commentPlugin", "meType"], {});
  const {
    host = "",
    accountId = "",
    primaryColor = "",
    className = "",
    secondaryColor = "",
    fontColor = "",
  } = metypeConfig;
  if (isEmpty(metypeConfig)) return null;
  const [feedWidget, setFeedWidget] = useState(null);
  useEffect(() => {
    async function loadFeed() {
      const Feed = await lazy(() => import(/* webpackChunkName: "metypeFeedWidget" */ "./metype-feedwidget"));
      setFeedWidget(
        <Feed
          host={host}
          accountId={parseInt(accountId)}
          publisher={publisherName}
          primaryColor={primaryColor}
          className={className}
          secondaryColor={secondaryColor}
          fontColor={fontColor}
        />
      );
    }
    loadFeed();
  }, []);
  return canUseDOM ? <Suspense fallback="">{feedWidget}</Suspense> : null;
}

MetypeBase.propTypes = {
  config: PropTypes.shape({
    "pagebuilder-config": PropTypes.object,
    "publisher-name": PropTypes.string,
  }),
};

function mapStateToProps(state) {
  return {
    config: state.qt.config || {},
  };
}

const Metype = connect(mapStateToProps, {})(MetypeBase);

export { Metype };
