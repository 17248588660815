/*
 *  ************************************************************************
 *  *  © [2015 - 2020] Quintype Technologies India Private Limited
 *  *  All Rights Reserved.
 *  *************************************************************************
 */
import React from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import get from "lodash/get";
import { Menu } from "./index";
import { getMenuData } from "../header-helper-components";

const getCustomCSS = (headerTheme) => {
  switch (headerTheme) {
    case "header_1":
      return "header-one-secondary";
    case "header_2":
      return "header-two-secondary";
    case "header_3":
      return "header-three-secondary";
    case "header_4":
      return "header-four-secondary";
    default:
      return "header-one-secondary";
  }
};

const SecondaryMenuBase = ({
  menu,
  languageDirection = "ltr",
  toggleSidebar,
  closeAll,
  customCssClasses = "",
  enableAppDownloadLinks,
  domainSlug,
}) => {
  const showAppDownloadLinks = enableAppDownloadLinks ? "show-app-download-links" : "";
  const menuGroup = domainSlug ? "header-secondary-menu" : "secondary-menu";
  return (
    <Menu
      menu={getMenuData(menu, menuGroup, domainSlug)}
      languageDirection={languageDirection}
      toggleSidebar={toggleSidebar}
      closeAll={closeAll}
      customCssClasses={`menu-row ${(customCssClasses, showAppDownloadLinks)}`}
      type="secondary"
    />
  );
};

const mapStateToProps = (state) => {
  const headerTheme = get(state.qt, ["config", "pagebuilder-config", "header", "headerStyle"], "header_1");
  const customCssClasses = getCustomCSS(headerTheme);
  return {
    menu: get(state.qt, ["data", "navigationMenu"], []),
    customCssClasses,
    enableAppDownloadLinks: get(
      state.qt,
      ["config", "pagebuilder-config", "header", "enableAppDownloadLinksButton"],
      false
    ),
    domainSlug: get(state, ["qt", "config", "domainSlug"], ""),
    languageDirection: get(state, ["qt", "config", "language", "direction"], "ltr"),
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    closeAll: () => dispatch({ type: "CLOSE_ALL" }),
    toggleSidebar: () => dispatch({ type: "TOGGLE_SIDEBAR_MENU" }),
  };
};

SecondaryMenuBase.propTypes = {
  menu: PropTypes.array,
  closeAll: PropTypes.func,
  languageDirection: PropTypes.string,
  toggleSidebar: PropTypes.func,
  customCssClasses: PropTypes.string,
  type: PropTypes.string,
  enableAppDownloadLinks: PropTypes.bool,
  domainSlug: PropTypes.string,
};

export const SecondaryMenu = connect(mapStateToProps, mapDispatchToProps)(SecondaryMenuBase);
